<!-- Breadcrumb -->
<nav class="breadcrumb bg-transparent mb-4 p-0 small">
  <ul class="breadcrumb-list list-inline d-flex mb-0">
    <li class="breadcrumb-list-item list-inline-item mr-0"><a class="breadcrumb-list-link" [routerLink]="['/']">Home</a></li>
    <li class="breadcrumb-list-item list-inline-item mr-0">Confirmación</li>
  </ul>
</nav>

<!-- Confirmation Order -->
<div class="confirmation-order-container">
  <div class="container">
    <div class="row" *ngIf="!loadingDataOrder">
      <div class="col-12">
        <h1 *ngIf="!orderData.medioPago.pasarela_pago_id" class="title-section text-center">
          Su pedido se realizó exitosamente
        </h1>
        <div *ngIf="orderData.medioPago.pasarela_pago_id" class="text-center">
          <span class="subtitle-section-home">Felicitaciones</span>
          <h2 class="title-section title-section-home h1 mb-0">Ahora solo falta el pago de tu pedido</h2>
        </div>
        <p class="text-center">
          Gracias por elegirnos, la siguiente información es el resumen del pedido
        </p>
      </div>
      <div class="col-12 offset-lg-4 col-lg-4">
        <!-- Payment Summary -->
        <div class="card card-order mb-3">
          <div class="card-body">
            <ul class="list-group list-group-flush">
              <li class="list-group-item d-flex justify-content-between">
                <span class="w700">N. de Pedido</span>
                <span>{{orderData.id}}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between">
                <span class="w700">Fecha</span>
                <span>{{orderData.fecha_elaboracion}}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between">
                <span class="w700">Valor {{orderData.estado_pago == 'Pagada' ? 'cancelado' :
                  (orderData.estado_pago == 'Pago pendiente' ? 'cancelado' : 'a Cancelar')}}</span>
                <span>$ {{orderData.total| number : '1.0-0'}}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between">
                <span class="w700">Medio de Pago</span>
                <span>{{orderData.medioPago.nombre}}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between">
                <span class="w700">Estado de Pago</span>
                <span>{{orderData.estado_pago}}</span>
              </li>
            </ul>
          </div>
        </div>
        <p class="text-center" ng-if="orderData.medioPago.id != 1">
          Hemos enviado a su correo electrónico un mensaje de confirmación en donde encontrará los detalles de su
          compra.
        </p>
        <!-- Actions -->
        <div class="text-center mb-3" *ngIf="orderData.medioPago.pasarela_pago_id">
          <button (click)="payNow()" class="btn btn-green btn-block text-uppercase small">
            Ir a pagar
          </button>
        </div>
      </div>
      <div class="col-12">
        <div class="text-center my-5">
          <!-- Component: Shopping Experience -->
          <app-shopping-experience [orderId]="orderId"></app-shopping-experience>
        </div>
        <!-- Actions Mobile -->
        <div class="btn-group mb-5 d-lg-none d-block" *ngIf="!orderData.medioPago.pasarela_pago_id">
          <button [routerLink]="['/order',orderData.id]" class="btn btn-outline-primary btn-block">
            Conocer el Estado de mi Pedido
          </button>
        </div>
        <!-- Actions Desktop -->
        <div class="btn-group mb-5 d-lg-flex d-none justify-content-center" *ngIf="!orderData.medioPago.pasarela_pago_id">
          <button [routerLink]="['/order',orderData.id]" class="btn btn-outline-primary mr-1">
            Conocer el Estado de mi Pedido
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-advertisements *ngIf="cmsData" [typeContent]="'espacios'" [positions]="1"
                    [contentData]="cmsData"></app-advertisements>
