<!-- Toast template -->
<ng-template #templateAfterAddToShoppingCart>
  <h5>{{product.name}}</h5>
  <p>Ha sido agregado correctamente</p>
  <P *ngIf="!itemIsAlreadyInCart">Cantidad añadida: <b>{{quantity}}</b></P>
  <P *ngIf="itemIsAlreadyInCart">Cantidad añadida: <b>{{productFactor}}</b></P>
  <div class="text-right">
    <a class="btn btn-light" [routerLink]="['/', 'order']">Ir a la Canasta</a>
  </div>
</ng-template>

<div class="box-product-container h-100 d-flex flex-column justify-content-between">
  <div class="box-product-image" *ngIf="product.imagesDetail">
    <!-- Badges -->
    <div class="box-product-badges d-flex flex-column small">
      <label class="box-product-badge box-product-featured small" *ngIf="product.isOutstanding">Destacado</label>
      <label class="box-product-badge box-product-offer small" *ngIf="product.isOffert">Oferta</label>
      <label class="box-product-badge box-product-new small" *ngIf="product.isNew">Nuevo</label>
      <label class="box-product-badge box-product-discount small" *ngIf="+product.discountPercentage && product.showValuePercentage">
        {{ product.discountPercentage | number: '1.0-2'}}%
      </label>
      <label class="box-product-badge box-product-offer small" *ngIf="product.isPromo">Promo</label>
    </div>
    <div class="box-product-exclusive small" *ngIf="product.isInternetExclusive"><small>Exclusivo internet</small></div>
    <!-- Image -->
    <a [routerLink]="['/', product.category.slug, product.slug]">
      <img [src]="product.imagesDetail[0].path + product.imagesDetail[0].image"
        [alt]="product.imagesDetail[0]?.alt || product.name"
        [title]="product.imagesDetail[0]?.title || product.name"
        class="img-fluid">
    </a>
  </div>
  <div class="box-product-info">
    <!-- Name -->
    <div class="box-product-name">
      <a class="box-product-link" [routerLink]="['/', product.category.slug, product.slug]">{{product.name}}</a>
    </div>
    <!--rating-->
    <div class="d-flex justify-content-center" *ngIf="showRating">
      <ngb-rating [(rate)]="rating" [max]="5" [readonly]="true"></ngb-rating>
    </div>
    <!-- Price -->
    <div class="box-product-price">
      <!-- Price From -->
      <span *ngIf="product.currentPrice">
        ${{ product.currentPrice | number : '1.0-0' }}
      </span>
      <!-- Price Before -->
      <span *ngIf="((product.currentPrice > 0 && (product.currentPrice !== product.lastPrice) ))" class="box-product-price-before">
        ${{ product.lastPrice | number : '1.0-0' }}
      </span>
      <!-- PUM -->
      <span *ngIf="product.mostrar_price_unity_measure && product.unidad_pum && product.pum">
        {{product.unidad_pum}} a $ {{product.pum}}
      </span>
    </div>
    <!-- Not Available -->
    <!--     <div class="box-product-price" *ngIf="product.isSoldOut || !product.currentPrice">
      <span class="fs-nd">
        Solo Disponible En Red Comercial
      </span>
    </div> -->
    <!-- VAT Included -->
    <span class="small d-flex justify-content-center mb-3" [hidden]="!product.currentPrice">{{product.taxText}}</span>
    <!-- Actions Product -->
    <div class="box-product-actions">
      <!-- Favorite -->
      <a class="box-product-favorite btn btn-circle mr-2"
        (click)=" toggleFavoriteProduct();"
        [hidden]="!(authService.isAuthenticated || product.isSettingAsFavorite)"
        [ngClass]="{'selected': product.isFavorite}"
        ngbTooltip="Favorito"
        placement="top">
        <i class="icon-heart"></i>
      </a>
      <!-- Add -->
      <a class="btn btn-primary box-product-add small"
        *ngIf="product.showProductAction && product.cantidad"
        (click)="addToCart()">
        <i class="lni lni-shopping-basket mr-1"></i> <small class="text-uppercase d-none d-lg-inline">Agregar</small>
      </a>
      <!-- View more -->
      <a class="btn box-product-add small"
        *ngIf="!product.showProductAction"
        [routerLink]="['/', product.category.slug, product.slug]">
        <i class="lni lni-circle-plus mr-1"></i> <small class="text-uppercase d-none d-lg-inline">Ver más</small>
      </a>
      <!-- Compare -->
      <a class="box-product-compare btn btn-circle ml-2"
        [ngClass]="{selected: selectedToCompare()}"
        (click)="setItemToCompare()"
        ngbTooltip="Comparar"
        placement="top">
        <i class="icon-shuffle"></i>
      </a>
    </div>
  </div>
</div>
