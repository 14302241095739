<!-- Breadcrumb -->
<nav class="breadcrumb bg-transparent mb-4 p-0 small">
  <ul class="breadcrumb-list list-inline d-flex mb-0">
    <li class="breadcrumb-list-item list-inline-item mr-0"><a class="breadcrumb-list-link" [routerLink]="['/']">Home</a></li>
    <li class="breadcrumb-list-item list-inline-item mr-0"><a class="breadcrumb-list-link" [routerLink]="['/','headquarters']">Sedes</a></li>
    <li class="breadcrumb-list-item list-inline-item mr-0">{{operatingCenterData?.name}}</li>
  </ul>
</nav>

<!-- Headquarters Detail -->
<div class="container">
  <div class="row">
    <div class="col-12">
      <h1 class="title-section text-center">
        {{operatingCenterData?.name}}
      </h1>
    </div>
  </div>
</div>
<!-- Headquarters -->
<div class="headquartes-detail-container">
  <div class="container">
    <div class="row">
      <!-- Headquarters Image -->
      <div class="col-12 col-lg-9">
        <img id="imgContact" class="img-fluid mb-3" alt="{{operatingCenterData?.name}}"
             [src]="operatingCenterData?.image">
        <div [innerHTML]="operatingCenterData?.description"></div>
      </div>
      <div class="col-12 col-lg-3">
        <!-- Headquarters Info -->
        <ul class="list-group mb-3">
          <li class="list-group-item active w700">
            <i class="icon icon-notebook"></i> Información
          </li>
          <li class="list-group-item" *ngIf="operatingCenterData?.PBX">
            <i class="icon icon-phone"></i> {{operatingCenterData?.PBX}}
          </li>
          <li class="list-group-item" *ngIf="operatingCenterData?.address">
            <i class="icon icon-location-pin"></i> {{operatingCenterData?.address}}
          </li>
          <li class="list-group-item"*ngIf="operatingCenterData?.city">
            <i class="icon icon-home"></i> {{operatingCenterData?.city}}
          </li>
          <li class="list-group-item" *ngIf="operatingCenterData?.schedules">
            <i class="icon icon-clock"></i> {{operatingCenterData?.schedules}}
          </li>
        </ul>
        <!-- Headquarters Services -->
        <ul class="list-group" *ngIf="operatingCenterData && operatingCenterData?.services">
          <li class="list-group-item active w700">
            <i class="icon icon-notebook"></i> Servicios
          </li>
            <li class="list-group-item" *ngFor="let service of operatingCenterData?.services">
              <i class="icon icon-check"></i> {{service.descripcion}}
            </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="w-100 mt-3">
    <!-- Headquarters Location -->
    <agm-map [latitude]='map.latitude' [longitude]='map.longitude' [mapTypeId]='map.mapType'>
      <agm-marker [latitude]="map.latitude" [longitude]="map.longitude"
        [markerDraggable]="false">
      </agm-marker>
    </agm-map>
  </div>
<!-- ADS -->
<app-advertisements *ngIf="cmsData" [typeContent]="'espacios'" [positions]="1"
                    [contentData]="cmsData"></app-advertisements>
