<div class="box-search-container position-relative">
  <div class="input-group">
    <input #inputSearch
           [placeholder]="placeholder"
           type="text"
           [class]="className"
           class="border-right-0"
           (input)="search(inputSearch.value)">
    <div class="input-group-append">
      <span class="input-group-text bg-transparent border-left-0">
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="searching"></span>
        <span *ngIf="!searching">
          <svg class="bi bi-search" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z" clip-rule="evenodd" />
            <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
              clip-rule="evenodd" />
          </svg>
        </span>
      </span>
    </div>
  </div>
  <div class="box-search-results w-100" *ngIf="showSearchResults && inputSearch.value.length >= minLength">
    <ul class="list-group" *ngIf="(results$ | async) as items">
      <li class="list-group-item" *ngFor="let item of items" (click)="select(item); inputSearch.value = ''">
        {{item.text}}
      </li>
      <li class="list-group-item normal" *ngIf="!items.length">
        Lamentablemente la ciudad '<strong>{{inputSearch.value}}</strong>' no está
        dentro de nuestra cobertura. Para solicitar ayuda haga clic <a class="text-danger" [routerLink]="['/', 'contact']">aquí</a>
      </li>
    </ul>
  </div>
</div>
