<ul class="list-group list-group-flush">
    <li class="list-group-item px-0" *ngFor="let attribute of attributes">
        <h6>{{attribute.title}}</h6>
        <div class="custom-control custom-switch"  *ngFor="let subAttribute of attribute.data">
            <input type="checkbox"
                class="custom-control-input"
                (change)="handleChange(subAttribute)"
                [checked]="isChecked(subAttribute)"
                [id]="'checkbox-' + subAttribute.id">
            <label class="custom-control-label"  [for]="'checkbox-' + subAttribute.id">{{subAttribute.value}}</label>
        </div>
    </li>
</ul>
