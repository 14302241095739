<!-- content -->
<div *ngIf="(category$ | async) as category">

  <!-- Breadcrumb -->
  <nav class="breadcrumb bg-transparent mb-4 p-0 small">
    <ul class="breadcrumb-list list-inline d-flex mb-0">
      <li class="breadcrumb-list-item list-inline-item mr-0"><a class="breadcrumb-list-link" [routerLink]="['/']">Home</a></li>
      <li class="breadcrumb-list-item list-inline-item mr-0" *ngFor="let breadcrumbItem of category.breadcrumb.slice(0, -1)">
        <a class="breadcrumb-list-link" [routerLink]="['/', breadcrumbItem?.url.split('/')[2]]" [title]="breadcrumbItem.title">{{breadcrumbItem.name}}</a>
      </li>
      <li class="breadcrumb-list-item list-inline-item mr-0">{{category.title}}</li>
    </ul>
  </nav>

  <!-- Categories -->
  <div class="categories-container">
    <div class="container">
      <div class="row">
        <!-- Content-->
        <div class="col mb-5">
          <!-- Header Category -->
          <header *ngIf="!category.title">
            <h1 class="title-section text-center">Nuestros Productos</h1>
            <p class="text-justify" *ngIf="parametersService?.company.config.data.categories.textOurProducts">
              {{parametersService?.company.config.data.categories.textOurProducts}}
            </p>
          </header>
          <header *ngIf="category.title">
            <h1 class="title-section text-center">{{category.title}}</h1>
            <p class="text-justify" [innerHTML]="category.description"></p>
          </header>
          <div class="row">

            <!-- Banner Category -->

            <div class="col-12 mb-3 d-none d-lg-block" *ngIf="category?.bannerImgComplete">
              <img [src]="category?.bannerImgComplete" [alt]="category?.title" alt="Placeholder" class="img-fluid">
            </div>
            <!-- Section Categories -->
            <div class="col-6 col-lg-3" *ngFor="let subcategory of  (treeCategory$ | async) ">
              <div class="category-container">
                <!-- Images -->
                <div class="category-container-image d-flex justify-content-center mb-3">
                  <!-- [routerLink]="['/', subcategory.slug, 'categories']" -->
                  <a [routerLink]="getLink(subcategory)" [title]="subcategory.nombre">
                    <img [src]="subcategory.imagen" [alt]="subcategory.imagen_alt_seo" [title]="subcategory.imagen_title_seo"
                      class="w-100 img-fluid">
                  </a>
                </div>
                <!-- Name -->
                <div class="category-container-name">
                  <!-- [routerLink]="['/', subcategory.slug, 'categories']" -->
                  <a class="category-container-link" [routerLink]="getLink(subcategory)" [title]="subcategory.nombre">{{ subcategory.nombre }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
