<!-- Breadcrumb -->
<nav aria-label="breadcrumb">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ol class="breadcrumb small px-0 bg-transparent">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="icon icon-home"></i>
            </a>
          </li>
          <li class="breadcrumb-item active">
            <span class="w700">
              Recuperar Contraseña
            </span>
          </li>
        </ol>
      </div>
    </div>
  </div>
</nav>
<!-- Header Recover Password -->
<div class="container">
  <div class="row">
    <div class="col-12">
      <h1 class="title-section text-center">
        Restablecer Contraseña
      </h1>
      <hr>
     <div [innerHTML]="cmsContrasenaIntro | safeHtml"></div>
    </div>
  </div>
</div>
<!-- Recover Password -->
<div class="recover-password-container">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-6 mb-3">
        <!-- Form Recover Password -->
        <form [formGroup]="recoveryPasswordForm"
              (ngSubmit)="sendRecoveryPassword()"
              novalidate>
          <!-- Email -->
          <div class="form-group">
            <label>Correo Electrónico*</label>
            <app-input placeholder="Correo Electrónico"
                       [inputControl]="recoveryPasswordForm.get('email')"
                       [feedback]="recoveryPasswordFormFeedback.email">
              <ng-container icon>
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <i class="fas fa-envelope"></i>
                  </div>
                </div>
              </ng-container>
            </app-input>
          </div>
          <!-- ReCaptcha -->
          <div class="form-group">
            <re-captcha formControlName="captcha"></re-captcha>
            <ng-container *ngIf="recoveryPasswordForm.get('captcha') as captcha">
              <p class="text-danger" *ngIf="captcha.invalid && (captcha.dirty || captcha.touched)">
                Comprueba que no eres un robot
              </p>
            </ng-container>
          </div>
          <!-- Actions Mobile -->
          <div class="form-group d-block d-lg-none">
            <button class="btn btn-primary btn-block mr-1"
                    type="submit"
                    [disabled]="recoveryPasswordDataLoading">
              <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="recoveryPasswordDataLoading"></span>
              Restablecer
            </button>
            <a role="button" class="btn btn-link btn-block" [routerLink]="['/','login']">
              Iniciar Sesión
            </a>
          </div>
          <!-- Actions Desktop -->
          <div class="form-group mb-3 d-none d-lg-flex">
            <button class="btn btn-primary"
                    type="submit"
                    [disabled]="recoveryPasswordDataLoading">
              <span *ngIf="recoveryPasswordDataLoading">
                <i class="fas fa-spinner fa-pulse fa-3x"></i>
              </span>
              Restablecer
            </button>
            <a *ngIf="!authService.isAuthenticated" role="button" class="btn btn-link mr-1" [routerLink]="['/','login']">
              Iniciar Sesión
            </a>
          </div>
        </form>
      </div>
      <div class="col-12 col-lg-6 mb-5">
        <div [innerHTML]="cmsContrasenaTips | safeHtml"></div>
      </div>
    </div>
  </div>
</div>
