<!-- Search Component Desktop -->
<div class="position-relative d-none d-lg-block">
  <!-- Form -->
  <form class="search-form" autocomplete="off" role="search">
    <input #inputSearch
      class="search-form-input"
      type="search"
      [placeholder]="placeholder"
      (input)="search(inputSearch.value)"
      (keydown.enter)="enter(inputSearch.value)">
    <button class="search-form-button" (click)="enter(inputSearch.value)"><i-feather name="search"></i-feather></button>
  </form>
  <!-- Results -->
  <div class="search-results position-absolute w-100" *ngIf="showResults">
    <div class="list-group list-result">
      <a *ngFor="let product of results"
         (click)="inputSearch.value = product.name; showResults = false"
         [routerLink]="[product.category.slug, product.slug ]"
         class="list-group-item list-group-item-action list-result-item rounded-0">
        {{product.name}}
      </a>
    </div>
  </div>
</div>


<!-- Search Component Mobile -->
<div class="position-absolute mobile-search-content w-100 d-block d-lg-none">
  <!-- Form -->
  <form autocomplete="off" class="search-form" role="search">
    <div class="position-relative">
      <input #inputSearchMobile type="search"
             (input)="search(inputSearchMobile.value)"
             (keydown.enter)="enter(inputSearchMobile.value)"
             [placeholder]="placeholder"
             class="form-control input-search rounded-0">
      <button class="btn-search" (click)="enter(inputSearch.value)">
        <i class="fa fa-search"></i>
      </button>
    </div>
  </form>
  <!-- Results -->
  <div class="position-absolute w-100" *ngIf="showResults">
    <div class="list-group list-result">
      <a *ngFor="let product of results"
          [routerLink]="['/', product.category.slug, product.slug]"
          (click)="inputSearchMobile.value = product.name; showResults = false"
          class="list-group-item list-group-item-action list-result-item rounded-0">
        <span>{{product.name}}</span>
      </a>
    </div>
  </div>
</div>