<!-- Breadcrumb -->
<nav class="breadcrumb bg-transparent mb-4 p-0 small">
  <ul class="breadcrumb-list list-inline d-flex mb-0">
    <li class="breadcrumb-list-item list-inline-item mr-0"><a class="breadcrumb-list-link" [routerLink]="['/']">Home</a></li>
    <li class="breadcrumb-list-item list-inline-item mr-0">Contáctenos</li>
  </ul>
</nav>

<!-- Contact Us -->
<div class="contact-us-container">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="title-section text-center">
          Contáctenos
        </h1>
      </div>
      <div class="col-12 col-lg-7 mb-5">
        <p class="text-justify">
          Tus inquietudes y comentarios serán atendidos oportunamente.
        </p>
        <!-- Form -->
        <form
          [formGroup]="registerForm"
          autocomplete="off"
          (ngSubmit)="onSubmit()"
          novalidate>
          <div class="form-row">
            <!-- Name -->
            <div class="form-group col-12 col-lg-6">
              <label>Nombre*</label>
              <app-input
                type="letter"
                placeholder="Nombre"
                icon="fas fa-user-circle"
                [inputControl]="registerForm.get('name')"
                [feedback]="{
                  required: 'Ingresa tu primer nombre',
                  maxlenght:
                    'Nombre no valido, su nombre debe ser menor a 50 letras'
                }"
              >
              </app-input>
            </div>
            <!-- Gender -->
            <div class="form-group col-12 col-lg-6">
              <label>Género*</label>
              <app-input
                type="select"
                icon="fas fa-venus-mars"
                [inputControl]="registerForm.get('gender')"
                [feedback]="{
                  required: 'Ingresa tu género'
                }"
              >
                <ng-container options>
                  <option disabled value="">Seleccione...</option>
                  <option value="Femenino">Femenino</option>
                  <option value="Masculino">Masculino</option>
                </ng-container>
              </app-input>
            </div>
          </div>
          <!-- Email -->
          <div class="form-group">
            <label>Correo Electrónico*</label>
            <app-input
              placeholder="email"
              icon="fas fa-envelope"
              [inputControl]="registerForm.get('email')"
              [feedback]="{
                required: 'Ingresa un email',
                pattern: 'Ingresa un email válido'
              }"
            >
            </app-input>
          </div>
          <div class="form-row">
            <!-- Celular -->
            <div class="form-group col-12 col-lg-6">
              <div class="form-row">
                <label class="col-12">Celular*</label>
                <app-input
                  type="number"
                  placeholder="57"
                  maxLength="4"
                  icon="fas fa-plus"
                  class="col-4"
                  [feedback]="{
                    required: 'Se requiere un indicativo de país',
                    min: 'Se necesita un indicativo valido'
                  }"
                  [inputControl]="registerForm.get('indicative')"
                >
                </app-input>
                <div class="form-group col-8">
                  <input
                    type="tel"
                    formControlName="phone"
                    placeholder="3001234567"
                    maxlength="10"
                    class="form-control"
                    [class.is-invalid]="
                      registerForm.get('phone').invalid &&
                      (registerForm.get('phone').touched ||
                        registerForm.get('phone').dirty)
                    "
                    appOnlyNumbers
                  />
                  <div class="invalid-feedback">
                    <span
                      *ngIf="registerForm.get('phone').hasError('required')"
                    >
                      Se require un teléfono movíl
                    </span>
                    <span
                      *ngIf="registerForm.get('phone').hasError('minlength')"
                    >
                      Su numero de teléfono movíl no puede ser menor a 10
                      dígitos
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- City -->
            <div class="form-group col-12 col-lg-6">
              <label>Ciudad</label>
              <app-input
                type="letter"
                placeholder="Ciudad"
                [maxLength]="100"
                [inputControl]="registerForm.get('city')"
              >
                <ng-container icon>
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <i class="fas fa-map-marker-alt"></i>
                    </div>
                  </div>
                </ng-container>
              </app-input>
              <!-- <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <i class="fas fa-map-marker-alt"></i>
                  </div>
                </div>
                <input id="contact-city" class="form-control" type="text" name="ciudad"
                       placeholder="Ciudad" formControlName="city">
              </div> -->
            </div>
          </div>
          <div class="form-row">
            <!-- Company -->
            <div class="form-group col-12 col-lg-6">
              <label>Empresa</label>
              <app-input
                placeholder="Empresa"
                icon="fas fa-address-card"
                [inputControl]="registerForm.get('company')"
              >
              </app-input>
              <!-- <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <i class="fas fa-address-card"></i>
                  </div>
                </div>
                <input id="contact-company" class="form-control" type="text" name="company"
                       placeholder="Empresa" formControlName="company">
              </div>
              <small class="mt-1 text-danger" *ngIf="registerForm.get('company').invalid && registerForm.get('company').touched">
                Ingresa el nombre de tu empresa
              </small> -->
            </div>
            <!-- Subject -->
            <div class="form-group col-12 col-lg-6">
              <label>Asunto*</label>
              <app-input
                type="select"
                icon="fas fa-envelope-open-text"
                [inputControl]="registerForm.get('subject')"
                [feedback]="{
                  required: 'Selecciona el asunto'
                }"
              >
                <ng-container options>
                  <option disabled value="">Seleccione...</option>
                  <option value="Reporte Fallas">Reporte Fallas</option>
                  <option value="Seguimiento de Pedido"
                    >Seguimiento de Pedido</option
                  >
                  <option value="Atención al cliente"
                    >Atención al Cliente</option
                  >
                  <option value="Asistencia Técnica">Asistencia Técnica</option>
                  <option value="Solicitar más información"
                    >Solicitar más Información</option
                  >
                  <option value="Cotización Especial"
                    >Cotización Especial</option
                  >
                  <option value="Otro">Otro</option>
                </ng-container>
              </app-input>
            </div>
          </div>
          <!-- Message -->
          <div class="form-group">
            <label>Mensaje*</label>
            <textarea
              id="contact-message"
              class="form-control"
              name="message"
              rows="4"
              maxlength="200"
              [class.is-invalid]="
                registerForm.get('message').invalid &&
                registerForm.get('message').touched
              "
              placeholder="Escribe tu mensaje*"
              formControlName="message"
            ></textarea>
            <small class="invalid-feedback">
              Escribe tu mensaje, comentario o requerimiento
            </small>
          </div>
          <!-- Captcha -->
          <div class="form-group">
            <div class="form-check">
              <input type="checkbox" formControlName="acceptPolicies"
                     id="acceptPolicies"
                     class="form-check-input"
                     [class.is-invalid]="registerForm.get('acceptPolicies').invalid">
              <label class="form-check-label" for="acceptPolicies">
                Acepto la <a [routerLink]="['/', 'politics', 'politicas-de-tratamiento-de-datos']" target="_blank">Politica de tratamiento de datos</a> y los <a [routerLink]="['/', 'politics', 'terminos']" target="_blank">Términos y Condiciones</a>*
              </label>
              <div class="invalid-feedback">
                Debes aceptar las politicas de tratamiento de datos y los terminos y condiciones
              </div>
            </div>
          </div>
          <div class="form-group">
            <re-captcha formControlName="captcha"></re-captcha>
          </div>
          <!-- Action Desktop -->
          <div class="form-group d-none d-lg-block">
            <button type="submit" class="btn btn-primary rounded-0">
              <span *ngIf="loading">
                <i class="fas fa-spinner fa-pulse"></i>
              </span>
              Enviar
            </button>
          </div>
          <!-- Action Mobile -->
          <div class="form-group d-block d-lg-none">
            <button
              type="submit"
              class="btn btn-primary btn-block rounded-0"
              [disabled]="registerForm.invalid || loading"
            >
              <span *ngIf="loading">
                <i class="fas fa-spinner fa-pulse"></i>
              </span>
              Enviar
            </button>
          </div>
          <!-- Alert Enviado -->
          <div class="alert alert-success text-center small" *ngIf="mailSent">
            El correo ha sido enviado exitosamente.<br />
            Uno de nuestros representantes comerciales se pondrá en contacto con
            usted lo más pronto posible.
          </div>
        </form>
      </div>
      <div class="col-12 col-lg-5 mb-5 d-lg-block d-none">
        <!-- ADS -->
        <app-advertisements
          *ngIf="cmsData"
          [typeContent]="'espacios'"
          [positions]="1"
          [contentData]="cmsData"
        ></app-advertisements>
      </div>
    </div>
  </div>
</div>
