<ng-container *ngIf="!switchSpinnerService.state">

  <!-- Breadcrumb -->
  <nav class="breadcrumb bg-transparent mb-4 p-0 small">
    <ul class="breadcrumb-list list-inline d-flex mb-0">
      <li class="breadcrumb-list-item list-inline-item mr-0"><a class="breadcrumb-list-link" [routerLink]="['/']">Home</a></li>
      <li class="breadcrumb-list-item list-inline-item mr-0">Favoritos</li>
    </ul>
  </nav>

  <div class="favorites-container">
    <div class="container">
      <!-- Header Favorites -->
      <div class="row">
        <div class="col-12 mb-3">
          <h1 class="title-section text-center">
            Favoritos
          </h1>
        </div>
      </div>
      <!-- Content Favorites -->
      <section>
        <div class="row">
          <div class="col-12 mb-3">
            <!-- Accordion Filters Options -->
            <div id="accordion">
              <div class="card filter-options border-0 position-relative">
                <div class="bg-white px-0" id="headingThree" #headingThree>
                  <div class="row align-items-center">
                    <!-- Quantity in View -->
                    <div class="col-auto">
                      <div class="form-inline">
                        <select class="form-control text-uppercase small text-body" (change)="updateListByPageSize($event.target.value)">
                          <option value="">Ver</option>
                          <option value="12">12</option>
                          <option value="24">24</option>
                        </select>
                      </div>
                    </div>
                    <div class="col align-self-end d-flex justify-content-end">
                      <!-- Search -->
                      <button type="button" class="btn btn-default"
                        [ngClass]="{'collapsed':!isCollapsedFilters}"
                        data-toggle="collapse"
                        data-target="#collapseSearch"
                        (click)="isCollapsedSearch = !isCollapsedSearch"
                        [attr.aria-expanded]="isCollapsedSearch" aria-controls="collapseSearch">
                        <i class="icon icon-magnifier"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <!-- Search -->
                <div id="collapseSearch" class="collapse-search card-body"
                     [ngbCollapse]="!isCollapsedSearch" [ngClass]="{'collapse':!isCollapsedSearch}"
                     aria-labelledby="headingSearch" data-parent="#accordion">
                  <!-- Search -->
                  <div class="position-relative">
                    <input #filterMobileText type="text" class="form-control" placeholder="Busca tu producto"
                           aria-label="Busca tu producto" aria-describedby="internal-search-addon"
                           (keydown.enter)="updateListByText(filterMobileText.value)"
                           (blur)="updateListByText(filterMobileText.value)">
                    <button class="btn btn-primary btn-search" type="button" id="internal-search-addon"
                            (click)="updateListByText(filterMobileText.value)">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container
          *ngIf="localSpinner; then localSpinnerTemplate; else productListTemplate">
        </ng-container>
        <ng-template #productListTemplate>
          <div class="row">
            <div class="col-12" *ngIf="favorites.length; else notFavorites ">
              <!-- product list -->
              <section class="box-product mb-2">
                <div class="row">
                  <div class="col-6 col-lg-3 mb-3" *ngFor="let product of favorites | sortBy:'asc': name">

                    <!-- Component: Box Products -->
                    <app-box-product [product]="product"></app-box-product>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </ng-template>
        <ng-template #localSpinnerTemplate>
          <div class="row d-flex justify-content-center align-items-center" style="height: 300px;">
            <div class="d-flex flex-column text-center">
              <div class="spinner-grow text-primary" style="width: 6rem; height: 6rem;" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <p class="mt-1 w700"> Cargando...</p>
            </div>
          </div>
        </ng-template>
      </section>
      <ng-template #notFavorites>
        <div class="col-12">
          <div class="alert alert-info" role="alert">
            No hay Favoritos
          </div>
        </div>
      </ng-template>
      <section
        *ngIf="!localSpinner"
        class="d-flex flex-column justify-content-center align-items-center small mb-5">
        <div class="mr-1">
          <ngb-pagination [(page)]="pagination.page" [pageSize]="pagination.pageSize" [maxSize]="3"
            [collectionSize]="pagination.total" (pageChange)="handlePageChange($event)"></ngb-pagination>
        </div>
        <label class="mt-3 mb-0">
          Mostrando {{ pagination.total }} productos de {{ pagination.page }}
          a {{ pagination.pageSize }}
        </label>
      </section>
    </div>
  </div>
</ng-container>
