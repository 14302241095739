<section [ngSwitch]="typeContent" class="container-fluid p-0">
  <div *ngSwitchCase="'espacios'">
    <div *ngIf="contentData[typeContent][positions]">
      <div class="cms-space" [innerHTML]="contentData[typeContent][positions].html | domSeguro"></div>
    </div>
  </div>
  <div *ngSwitchCase="'carrusel'">
    <div *ngIf="contentData?.carrusel?.info" >
      <!-- Carrousel tipo banner -->
      <ngb-carousel #carousel
        interval="6000"
        *ngIf="contentData?.carrusel?.info[positions]?.tipo_carrusel == 1"
        [showNavigationArrows]="true"
        [showNavigationIndicators]="true"
        [pauseOnHover]="pauseOnHover">
        <ng-template ngbSlide *ngFor="let slide of contentData[typeContent]?.images[positions]">
          <a *ngIf="slide.url" [href]="slide.url">
            <img [src]="slide.imagen" class="img-fluid w-100" [alt]="slide.alt" [title]="slide.title">
          </a>
          <img *ngIf="!slide.url"  [src]="slide.imagen" class="img-fluid w-100" [alt]="slide.alt" [title]="slide.title">
        </ng-template>
      </ngb-carousel>
      <!-- Carrousel tipo marcas -->
      <section *ngIf="contentData?.carrusel?.info[positions]?.tipo_carrusel == 2">
        <div class="col-12 text-center">
          <span class="subtitle-section-home">Marcas</span>
          <h2 class="title-section title-section-home h1 mb-4">Aliadas</h2>
        </div>
        <div class="carousel-wrapper">
          <button *ng-if="false" class="btn carousel-wrapper--actions carousel-wrapper--actions--prev" (click)="scrollLeft(carouselContent)">
            <i class="icon icon-arrow-left"></i>
          </button>
          <div class="carousel-wrapper--content" #carouselContent>
            <div *ngFor="let slide of contentData[typeContent]?.images[positions]" class="img-fluid carrousel-wrapper--item">
              <a *ngIf="slide.url" [href]="slide.url" target="_blank">
                <img [src]="slide.imagen" [alt]="slide.imagen_id">
              </a>
              <img *ngIf="!slide.url" [src]="slide.imagen" [alt]="slide.imagen_id">
            </div>
          </div>
          <button *ng-if="false" class="btn carousel-wrapper--actions carousel-wrapper--actions--next" (click)="scrollRight(carouselContent)">
            <i class="icon icon-arrow-right"></i>
          </button>
        </div>
      </section>
    </div>
  </div>
  <p *ngSwitchDefault>
    Sin tipo
  </p>
</section>
