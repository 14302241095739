<!-- Modal: Calificar producto -->
<ng-template #modalRateContent>
  <textarea placeholder="Escribe aquí tu comentario..." #inputRateCommentary class="form-control"></textarea>
  <!-- Rate Product -->
  <div class="rate-product-container d-flex justify-content-between align-items-center">
    <div class="reviews">
      <span class="small">¿Qué calificación le das a este producto?</span>
    </div>
    <div class="ratings fa-2x">
      <ngb-rating #inputRateScore [max]="5"></ngb-rating>
    </div>
  </div>
  <div class="app-modal-footer modal-footer">
    <button type="button" class="btn btn-primary" (click)="qualificationRequest(inputRateCommentary.value, inputRateScore.rate)">Enviar</button>
  </div>
</ng-template>

<!--  Modal: Ver imagen -->
<ng-template #modalZoomContent let-picture='data'>
  <img [src]="picture.path + picture.image" [alt]="picture?.alt" [title]="picture?.title" class="img-fluid">
</ng-template>

<!--  Modal: Ver video -->
<ng-template #modalVideoContent let-video='data'>
  <div class="embed-responsive embed-responsive-4by3">
    <iframe [src]="getSanitizerVideoUrl(video.url)" allow="autoplay; encrypted-media">
    </iframe>
  </div>
</ng-template>

<!-- Component: Loader -->
<ngx-json-ld [json]="schema"></ngx-json-ld>
<div *ngIf="this.product$ | async as productDetails">
  <!-- Toast template -->
  <ng-template #templateAfterAddToShoppingCart>
    <h5>{{productDetails.name}}</h5>
    <p>Ha sido agregado correctamente</p>
    <P>Cantidad añadida <b>{{quantityAddToCart}}</b></P>
    <div class="text-right">
      <a class="btn btn-light" [routerLink]="['/', 'order']">Ir a la Canasta</a>
    </div>
  </ng-template>

  <!-- Breadcrumb -->
  <nav class="breadcrumb bg-transparent mb-4 p-0 small">
    <ul class="breadcrumb-list list-inline d-flex mb-0">
      <li class="breadcrumb-list-item list-inline-item mr-0"><a class="breadcrumb-list-link" [routerLink]="['/']">Home</a></li>
      <li class="breadcrumb-list-item list-inline-item mr-0" *ngFor="let breadcrumbItem of productDetails.breadcrumb.slice(0, -1)">
        <a class="breadcrumb-list-link" [routerLink]="['/', breadcrumbItem.url.split('/')[2]]" [title]="breadcrumbItem.title">{{breadcrumbItem.name}}</a>
      </li>
      <li class="breadcrumb-list-item list-inline-item mr-0" *ngFor="let breadcrumbItem of productDetails.breadcrumb.slice(-1)">
        <a class="breadcrumb-list-link" [routerLink]="['/', breadcrumbItem.url.split('/')[2], 'products']" [title]="breadcrumbItem.title">{{breadcrumbItem.name}}</a>
      </li>
      <li class="breadcrumb-list-item list-inline-item mr-0">{{productDetails.name}}</li>
    </ul>
  </nav>

  <!-- Product Deatils Content -->
  <div class="product-details-container">
    <div class="container">
      <div class="row mb-5" *ngIf="!loadingProductDetail; else loader">
        <!-- Images Product -->
        <div class="col-12 col-lg-6">
          <!--  Image Container -->
          <div id="product-image-container" *ngIf="productDetails.imagesDetail.length > 0">
            <!-- Badges Product -->
            <div class="product-details-badges d-flex flex-column small">
              <span class="product-details-badge product-details-featured small" *ngIf="productDetails.isOutstanding">Destacado</span>
              <span class="product-details-badge product-details-offer small" [hidden]="!productDetails.isOffert">Oferta</span>
              <span class="product-details-badge product-details-new small" [hidden]="!productDetails.isNew">Nuevo</span>
              <span class="product-details-badge product-details-discount small" [hidden]="!(productDetails.discountPercentage > 0) || !productDetails.showValuePercentage">
                {{ productDetails.discountPercentage | number: '1.0-2' }}%
              </span>
              <span class="product-details-badge product-details-promo small" [hidden]="!productDetails.isPromo">Promo</span>
            </div>
            <!-- Exlusive Product -->
            <div class="product-details-exclusive small" [hidden]="!productDetails.isInternetExclusive"><small>Exclusivo internet</small></div>
            <!-- Scroll Images -->
            <div class="slider-images" [ngClass]="{'slider-images-scroll':productDetails.imagesDetail.length > 1}">
              <div class="slider-images--item d-flex justify-content-center" *ngFor="let picture of productDetails.imagesDetail">
                <img class="img-fluid w-100"
                  [src]="picture.path + picture.image"
                  [alt]="picture?.alt || productDetails.name"
                  [title]="picture?.title || productDetails.name">
              </div>
            </div>
          </div>
        </div>
        <!-- Info Product -->
        <div class="col-12 col-lg-6">
          <!-- Name Product -->
          <h1 class="h5 text-secondary mb-4" [innerHTML]="productDetails.name"></h1>
          <!-- Rate Product -->
          <div class="d-flex align-items-center mb-4">
            <ngb-rating class="mr-1" [(rate)]="rating" [max]="5" [readonly]="true"></ngb-rating>
            <a class="product-details-link small text-uppercase mt-1"
              *ngIf="authService.isAuthenticated"
              (click)="openModal(modalRateContent, { title: 'Calificar producto' })">Agregar opinión</a>
          </div>
          <!-- Price Product -->
          <div class="product-details-price mb-4">
            <!-- Normal -->
            <div class="d-flex align-items-center" *ngIf="productDetails.currentPrice !== null && productDetails.showPrices && !productDetails.isTemplate">
              <h3 class="product-details-value text-primary mb-0 mr-3">
                ${{productDetails.currentPrice | number : '1.0-0'}}
              </h3>
            </div>
            <span class="d-block" *ngIf="productDetails.currentPrice !== null && productDetails.showPrices && !productDetails.isTemplate">
              {{productDetails.taxText}}
            </span>
            <!-- PLU -->
            <span class="d-block" *ngIf="(productDetails.mostrar_price_look_up)">PLU: {{productDetails.erpCode}}</span>
            <!-- Others -->
            <div class="d-flex align-items-center" *ngIf="!productDetails.quote">
              <h3 class="product-details-value text-primary mb-0 mr-3"
              *ngIf="productDetails.minValueClonedAttributes && productDetails.clonedAttributesDetail.length" class="d-flex align-items-center">
                ${{productDetails.minValueClonedAttributes | number : '1.0-0'}}
              </h3>
              <h5 class="product-details-before mb-0"
                *ngIf="productDetails.currentPrice !== null && productDetails.discountPercentage > 0 && productDetails.discountValue > 0">
                ${{productDetails.lastPrice  | number : '1.0-0'}}
              </h5>
            </div>
            <span class="d-block"
              *ngIf="productDetails.minValueClonedAttributes && productDetails.clonedAttributesDetail.length" class="d-flex align-items-center">
              {{productDetails.taxText}}
            </span>
            <!-- PUM -->
            <span class="d-block"*ngIf="(productDetails.mostrar_price_unity_measure && productDetails.unidad_pum && productDetails.pum)">
              {{productDetails.unidad_pum}} {{'a $' + productDetails.pum}}
            </span>
            <!-- Form -->
            <div *ngIf="productExt.clonedAttributesDetail.length && !productExt.quote">
              <div *ngIf="productExt.clonedAttributesDetail.length>1 || objectLength(productExt.clonedAttributesDetail[0].value)>1">
                <form novalidate>
                  <div class="form-group" *ngFor="let attribute of productExt.clonedAttributesDetail; let ind = index">
                    <label class="text-capitalize">{{attribute.title}}*:</label>
                    <select class="form-control"
                            (change)="clonedAttributesSelected(attribute.name, $event.target.value, ind)"
                            [(ngModel)]="clonedFormData[ind]"
                            [ngModelOptions]="{standalone: true}"
                            [disabled]="(ind > 0) && clonedFormData[0].length==0"
                            required>
                      <option value="">Seleccione una opción</option>
                      <option
                        *ngFor="let attr of (attribute.value | keys)"
                        [value]="attr.key"
                        [selected]="findDefaultSelected(attr.value, attribute.name)">{{attr.value}}</option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- Attributes-->
          <ul class="list-unstyled mb-4">
            <li class="mb-2" *ngIf="productDetails.unidades_por_um">
              <span class="text-secondary mr-1">{{productDetails.label_unidades_por_um}}:</span>
              <span>{{productDetails.unidades_por_um}}</span>
            </li>
            <li class="mb-2" *ngIf="productDetails.measureUnit">
              <span class="text-secondary mr-1">Unidad de Medida:</span>
              <span>{{productDetails.measureUnit}}</span>
            </li>
            <ng-container *ngFor="let attribute of attributes">
              <li class="mb-2" *ngIf="attribute.type === 'only' && attribute.typeId !== 1">
                <span class="text-secondary mr-1" scope="row" [innerHTML]="attribute.title.charAt(0).toUpperCase() + attribute.title.slice(1) + ':'"></span>
                <span [innerHTML]="attribute.value"></span>
              </li>
              <li class="mb-2" *ngIf="attribute.type === 'selected' && attribute.typeId !== 1">
                <span class="text-secondary mr-1" scope="row" [innerHTML]="attribute.title.charAt(0).toUpperCase() + attribute.title.slice(1) + ':'"></span>
                <span [innerHTML]="attribute.value.join()"></span>
              </li>
            </ng-container>
            <li class="mb-2" *ngIf="productDetails.reference">
              <span class="text-secondary mr-1">Referencia:</span>
              <span>{{productDetails.reference}}</span>
            </li>
            <li class="mb-2" *ngIf="productDetails.erpCode && !productDetails.mostrar_price_look_up">
              <span class="text-secondary mr-1">Código de ERP:</span>
              <span>{{productDetails.erpCode}}</span>
            </li>
            <li class="mb-2">
              <span class="text-secondary mr-1">Categoría:</span>
              <span>{{productDetails.category.title}}</span>
            </li>

            <!-- Link del fabricante -->
            <li class="mb-2" *ngIf="productDetails.linkFabricante">
              <span class="text-secondary mr-1">Link del fabricante:</span>
              <a
                 href="{{productDetails.linkFabricante}}"
                 target="_blank"
                 title="Link del fabricante">
                {{productDetails.linkFabricante}}
              </a>
            </li>
          </ul>
          <!-- Description -->
          <div class="mb-4">
            <div *ngIf="productDetails.descriptionAxB" [innerHTML]="productDetails.descriptionAxB"></div>
            <div [innerHTML]="productDetails.shortDescription"></div>
          </div>
          <!--Additional Instructions-->
          <div class="options-item">
            <!--Title-->
            <div class="options-title">
              <h5 class="mb-2">Instrucciones Adicionales</h5>
            </div>
            <!--Check-->
            <form>
              <div class="form-group">
                <textarea class="form-control" #inputItemComment [value]="commentProduct" rows="5" id="comment" placeholder="Personaliza tu pedido"></textarea>
              </div>
            </form>
          </div>
          <!-- Actions Product -->
          <div class="mb-4" *ngIf="!productDetails.isTemplate">
            <!-- Product not Available -->
            <!--<h5 class="text-danger" *ngIf="productDetails.currentPrice === null || productDetails.isSoldOut">Solo Disponible En Red Comercial</h5>-->
            <!-- Product Add -->
            <div class="row"
              [hidden]="productDetails.currentPrice === null || productDetails.isSoldOut"
              *ngIf="productDetails.showProductAction || productDetails.showClonedAction">
              <!-- Quantity -->
              <div class="col-auto">
                <!-- Component: Quantity -->
                <app-quantity [quantity]="quantity"
                  [min]="(itemAlreadyInCart() ? productDetails.factor_pedido : productDetails.cantidad_minima)"
                  [factor]="productDetails.factor_pedido" (changeQuantity)="handleQuantity($event)"
                  [updatingQuantityPd]="true">
                </app-quantity>
              </div>
              <!-- Add Button -->
              <div class="col-auto pr-0">
                <a class="product-details-action btn btn-primary"
                  [disabled]="disabledAddToCartButton()"
                  (click)="addItemToCart(productDetails, templateAfterAddToShoppingCart, inputItemComment.value)">
                  <i class="lni lni-shopping-basket mr-2"></i> <small class="text-uppercase">Agregar</small>
                </a>
              </div>
              <!-- Favorite -->
              <div class="col-auto"[hidden]="productDetails.isTemplate">
                <a class="product-details-favorite btn btn-secondary"
                  placement="top"
                  ngbTooltip="Favorito"
                  (click)="toggleFavoriteProduct(productDetails)"
                  [ngClass]="{'selected': productDetails.isFavorite}"
                  [hidden]="!authService.isAuthenticated || productDetails.isSettingAsFavorite">
                  <i class="icon-heart h5 m-0"></i>
                </a>
              </div>
            </div>
          </div>
          <!-- Contact More Information -->
          <button class="product-details-action btn btn-primary mb-4"
            [routerLink]="['/contact']"
            *ngIf="(productDetails.showProductDetailContactAction && !productDetails.isTemplate)
            || (productDetails.currentPrice === null
            || productDetails.currentPrice === 0)
            || productDetails.isSoldOut">
            <i class="lni lni-circle-plus mr-1"></i> <small class="text-uppercase">Más información</small>
          </button>
          <!-- Share Product -->
          <div class="product-details-share d-flex align-items-center">
            <a class="product-details-share-link"
              href="https://www.facebook.com/sharer/sharer.php?u={{urlProduct()}}"
              target="_blank"
              title="Compartir en Facebook">
              <i class="fab fa-facebook-f" aria-hidden="true"></i>
            </a>
            <a class="product-details-share-link"
              href="https://twitter.com/intent/tweet?url={{urlProduct()}}"
              target="_blank"
              title="Compartir en Twitter">
              <i class="fab fa-twitter" aria-hidden="true"></i>
            </a>
            <a class="product-details-share-link"
              href="https://co.pinterest.com/pin/create/button/?url={{urlProduct()}}&media={{productDetails.imagesDetail[0].path + productDetails.imagesDetail[0].image}}&description={{productDetails.name}}"
              target="_blank"
              title="Compartir en Pinterest">
              <i class="fab fa-pinterest-p" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
      <!-- More Information -->
      <div [hidden]="loadingProductDetail">
               <!-- Equivalent Products -->
               <div class="row">
                <div class="col-12 text-center">
                  <span class="subtitle-section-home">Productos</span>
                  <h2 class="title-section title-section-home h1 mb-5">Equivalentes</h2>
                </div>
              </div>
              <!-- Component: Related Products -->
              <div class="row">
                <div class="col-6 col-lg-3 mb-3" *ngFor="let relatedProduct of relatedProducts">
                  <app-box-product [product]="relatedProduct"></app-box-product>
                </div>
              </div>
        <!-- Long Description -->
        <div class="row mb-3" *ngIf="productDetails.description !== ''">
          <div class="col-10">
            <h2 class="title-section mb-2">Beneficios, atributos, usos y aplicaciones</h2>
            <div *ngIf="!showLongDescription" [innerHTML]="productDetails.description | safeHtml"></div>
          </div>
          <div class="col-2">
            <a (click)="showLongDescription = !showLongDescription" type="button" class="btn btn-md btn-secondary">
              <i class="fa fa-plus"></i>
            </a>
          </div>
        </div>
        <!-- Tabs -->
        <div class="row">
          <div class="col-12">
            <nav ngbNav [(activeId)]="activeId" #nav="ngbNav" class="nav nav-tabs nav-scroll">
              <!-- Product Datasheet -->
              <ng-container [ngbNavItem]="1" *ngIf="productDetails.datasheet.length">
                <a ngbNavLink class="nav-item">Ficha Técnica</a>
                <ng-template ngbNavContent>
                  <div class="list-group">
                    <a class="list-group-item list-group-item-action" *ngFor="let data of productDetails.datasheet">
                      <i class="far fa-file-alt"></i> {{data}}
                    </a>
                  </div>
                </ng-template>
              </ng-container>
              <ng-container [ngbNavItem]="2" *ngIf="attributes.length">
                <a ngbNavLink class="nav-item">Detalles</a>
                <ng-template ngbNavContent>
                  <div class="table-responsive">
                    <table class="table mb-0">
                      <thead>
                      <tr>
                        <th>Atributos</th>
                        <th>Detalle</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let attribute of attributes" [hidden]="attribute.type === 'only'">
                        <td>{{attribute.name | uppercaseFirstLetter }}</td>
                        <td>{{attribute.value}}</td>
                      </tr>
                      <tr *ngFor="let attribute of attributes" [hidden]="attribute.type === 'selected'">
                        <td>{{attribute.title | uppercaseFirstLetter }}</td>
                        <td>
                          {{attribute.value}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </ng-template>
              </ng-container>
              <!-- Nav: Videos -->
              <ng-container [ngbNavItem]="3" *ngIf="productDetails.videos.length">
                <a ngbNavLink>Videos</a>
                <ng-template ngbNavContent>
                  <div class="list-group">
                    <a class="list-group-item list-group-item-action" (click)="openModal(modalVideoContent, video)"
                       *ngFor="let video of productDetails.videos">
                      <img [src]="video.videoId" [alt]="video.title" [title]="video.title" class="mr-2" width="30px">
                      <span>{{video.title}}</span>
                    </a>
                  </div>
                </ng-template>
              </ng-container>
              <!-- Navs: Links -->
              <ng-container [ngbNavItem]="4" *ngIf="productDetails.urls.length">
                <a ngbNavLink class="nav-item">Enlaces</a>
                <ng-template ngbNavContent>
                  <div class="list-group">
                    <a class="list-group-item list-group-item-action" *ngFor="let url of productDetails.urls"
                       [href]="url.url" target="_blank" [title]="url.title">
                      <i class="fas fa-file-alt"></i> {{url.title}}
                    </a>
                  </div>
                </ng-template>
              </ng-container>
              <!-- Navs: Data Sheet -->
              <ng-container [ngbNavItem]="5" *ngIf="productDetails.attachments.length || productDetails.category.attachments.length">
                <a ngbNavLink
                   class="nav-item">{{parametersService.company?.config?.data?.item?.annexedDocumentString}}</a>
                <ng-template ngbNavContent>
                  <!-- Annexes Products -->
                  <div class="list-group" *ngIf="productDetails.attachments.length">
                    <a class="list-group-item list-group-item-action"
                       *ngFor="let attachment of productDetails.attachments" href="{{attachment.url}}" target="_blank"
                       title="{{attachment.title}}">
                      <i class="fas fa-file-alt"></i> {{attachment.title}}
                    </a>
                  </div>
                  <!-- Annexes CAtegories -->
                  <div class="list-group" *ngIf="productDetails.category.attachments.length">
                    <a class="list-group-item list-group-item-action"
                       *ngFor="let attachment of productDetails.category.attachments" href="{{attachment.url}}"
                       target="_blank" title="{{attachment.title}}">
                      <i class="fas fa-file-alt"></i> {{attachment.title}}
                    </a>
                  </div>
                </ng-template>
              </ng-container>
              <!-- Navs: Complement -->
              <ng-container [ngbNavItem]="6" *ngIf="partSuppliesProduct.length">
                <a ngbNavLink class="nav-item">Complementos</a>
                <ng-template ngbNavContent>
                  <div class="table-responsive">
                    <table class="table mb-0">
                      <thead>
                      <tr>
                        <th width="45%">Nombre</th>
                        <th width="20%">Referencia</th>
                        <th width="20%">Tipo</th>
                        <th width="15%">Acción</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let partsupply of partSuppliesProduct">
                        <td>
                          <a [routerLink]="['/', partsupply.category.slug, partsupply.slug]"
                             [ngbTooltip]="partsupply.name">{{partsupply.name}}
                          </a>
                        </td>
                        <td>
                          {{partsupply.reference}}
                        </td>
                        <td>
                          {{partsupply.tipo_relacionado}}
                        </td>
                        <td>
                          <button
                            *ngIf="((!partsupply.isSoldOut && partsupply.currentPrice !== null)
                                              || (partsupply.currentPrice == null && partsupply.quote)) && !partsupply.isTemplate"
                            class="btn btn-sm btn-outline-primary" (click)="addItemToCart(partsupply)">
                            <i class="icon-bag"></i> {{partsupply.textAction}}
                          </button>
                          <button
                            *ngIf="((!partsupply.isSoldOut && partsupply.currentPrice !== null)
                                              || (partsupply.currentPrice == null && partsupply.quote)) && partsupply.isTemplate"
                            class="btn btn-sm btn-outline-primary"
                            [routerLink]="['/', partsupply.category.slug, partsupply.slug]">
                            <i class="icon-bag"></i> Ver Detalles
                          </button>
                          <span *ngIf="partsupply.isSoldOut">
                              -
                            </span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </ng-template>
              </ng-container>
              <!-- Navs: Substitutes -->
              <ng-container [ngbNavItem]="7" *ngIf="partSustitutesProduct.length">
                <a ngbNavLink class="nav-item">Sustitutos</a>
                <ng-template ngbNavContent>
                  <div class="table-responsive">
                    <table class="table mb-0">
                      <thead>
                      <tr>
                        <th width="45%">Nombre</th>
                        <th width="20%">Referencia</th>
                        <th width="20%">Tipo</th>
                        <th width="15%">-</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let partSustitute of partSustitutesProduct">
                        <td>
                          <a [routerLink]="['/', partSustitute.category.slug, partSustitute.slug]"
                             [ngbTooltip]="partSustitute.name">
                            {{partSustitute.name}}
                          </a>
                        </td>
                        <td>
                          {{partSustitute.reference}}
                        </td>
                        <td>
                          {{partSustitute.tipo_relacionado}}
                        </td>
                        <td>
                          <button
                            *ngIf="((!partSustitute.isSoldOut && partSustitute.currentPrice !== null)
                                              || (partSustitute.currentPrice == null && partSustitute.quote)) && !partSustitute.isTemplate"
                            class="btn btn-sm btn-outline-primary" (click)="addItemToCart(partSustitute)">
                            <i class="icon-bag"></i> {{partSustitute.textAction}}
                          </button>
                          <button
                            *ngIf="((!partSustitute.isSoldOut && partSustitute.currentPrice !== null)
                                              || (partSustitute.currentPrice == null && partSustitute.quote)) && partSustitute.isTemplate"
                            class="btn btn-sm btn-outline-primary"
                            [routerLink]="['/', partSustitute.category.slug, partSustitute.slug]">
                            <i class="icon-bag"></i> Ver Detalles
                          </button>
                          <span *ngIf="partSustitute.isSoldOut">
                              -
                            </span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </ng-template>
              </ng-container>
              <!-- Navs: Spare Parts -->
              <ng-container [ngbNavItem]="8" *ngIf="partSparesProducts.length">
                <a ngbNavLink class="nav-item">Repuestos</a>
                <ng-template ngbNavContent>
                  <div class="table-responsive">
                    <table class="table mb-0">
                      <thead>
                      <tr>
                        <th width="45%">Nombre</th>
                        <th width="20%">Referencia</th>
                        <th width="20%">Tipo</th>
                        <th width="15%">Acción</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let partSpare of partSparesProducts">
                        <td>
                          <a class="nombre-producto" [routerLink]="['/', partSpare.category.slug, partSpare.slug ]"
                             [ngbTooltip]="partSpare.name">
                            {{partSpare.name}}
                          </a>
                        </td>
                        <td>
                          {{partSpare.reference}}
                        </td>
                        <td>
                          {{partSpare.tipo_relacionado}}
                        </td>
                        <td>
                          <button
                            *ngIf="((!partSpare.isSoldOut && partSpare.currentPrice !== null)
                                              || (partSpare.currentPrice == null && partSpare.quote)) && !partSpare.isTemplate"
                            class="btn btn-sm btn-outline-primary" (click)="addItemToCart(partSpare)">
                            <i class="icon-bag"></i> {{partSpare.textAction}}
                          </button>
                          <button
                            *ngIf="((!partSpare.isSoldOut && partSpare.currentPrice !== null)
                                              || (partSpare.currentPrice == null && partSpare.quote)) && partSpare.isTemplate"
                            class="btn btn-sm btn-outline-primary"
                            [routerLink]="['/', partSpare.category.slug, partSpare.slug]">
                            <i class="icon-bag"></i> Ver detalles
                          </button>
                          <span *ngIf="partSpare.isSoldOut">
                              -
                            </span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </ng-template>
              </ng-container>
              <!-- Navs: Assistance -->
              <!-- <ng-container ngbNavItem>-->
              <!-- <a ngbNavLink class="nav-item">Asistencia</a>-->
              <!-- <ng-template ngbNavContent>-->
              <!-- <a [routerLink]="['/contact']">-->
              <!-- &lt;!&ndash; TODO: Espacio-->
              <!-- <advertisement class="center-block" sh-ctrl="shCtrl" type-content="espacios"-->
              <!-- positions="2" content-data="cmsData"></advertisement> &ndash;&gt;-->
              <!-- </a>-->
              <!-- </ng-template>-->
              <!-- </ng-container>-->
            </nav>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 mb-3">
            <div [ngbNavOutlet]="nav"></div>
          </div>
        </div>
        <!-- Ratings -->
        <div class="row">
          <div class="col-12 my-3">
            <h2 class="title-section mb-2">Calificaciones</h2>
            <ng-template #notComments>
              <div class="alert alert-info">
                <p class="mb-0">No existen calificaciones para el producto</p>
              </div>
            </ng-template>
            <ng-container *ngIf="comments.length; else notComments">
              <div class="d-flex mb-3">
                <div class="form-group mb-0">
                  <select class="form-control" (change)="filtersRating(rate.value)" #rate ng-model="property">
                    <option value="">Ordenar:</option>
                    <option value="rate">Por calificación</option>
                    <option value="date">Por fecha y hora</option>
                  </select>
                </div>
                <div class="btn btn-primary">
                  <i class="fas fa-chevron-up"></i>
                </div>
                <span class="btn btn-primary">
                  <i class="fas fa-chevron-down"></i>
                </span>
              </div>
              <!-- Rate Product -->
              <div class="row" *ngIf="!showAllComments">
                <div class="col-12 col-lg-4 mb-3" *ngFor="let comment of comments.reverse()">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">"{{comment.comentario}}"</h5>
                      <p class="card-text">{{comment.usuario_nombre}}</p>
                      <div class="rate-product-container d-flex align-items-center">
                        <div class="ratings fa-2x">
                          <ngb-rating [(rate)]="comment.puntaje" [max]="5" [readonly]="true"></ngb-rating>
                        </div>
                        <div class="reviews ml-3">
                          <span class="small mr-1"><i><small>{{comment.fecha}}</small></i></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" [hidden]="comments.length > 0">
                  <div class="col-12">
                    <button (click)="showAllComments = !showAllComments" class="btn btn-block btn-primary"
                            type="button">
                      <i class="fa fa-chevron-down mr-1"></i> Ver todas las opiniones
                    </button>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="showAllComments">
                <div class="col-12" [hidden]="comments.length > 2">
                  <button (click)="showAllComments = !showAllComments" class="btn btn-block btn-primary" type="button">
                    <i class="fa fa-chevron-up mr-1"></i>Ver menos
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
 
      </div>
    </div>
  </div>

</div>
