<!-- Megamenu Desktop -->
<li class="d-none d-lg-block navbar-item list-inline-item">
  <a *ngIf="menu?.url_destino; else mainSlugRoute" class="navbar-item-link" [href]="menu?.url_destino">
    {{menu.nombre}}
  </a>
  <ng-template #mainSlugRoute>
    <a class="navbar-item-link d-flex align-items-center" [routerLink]="['/', menu.slug]">
      {{menu.nombre}} <i class="icon-arrow-down navbar-item-arrow"></i>
    </a>
  </ng-template>
  <div class="dropdown-menu animate slideIn" *ngIf="menu.hijas && menu.hijas.length">
    <ng-template #recursiveListChild let-list>
      <div class="dropdown-menu-content">
        <div *ngFor="let item of list">
          <div class="dropdown-menu-item">
            <a *ngIf="item?.url_destino; else slugRoute"
               class="dropdown-menu-link d-flex align-items-center justify-content-between small"
               [href]="item?.url_destino">
                  <span [ngClass]="{'text-capitalize':parametersService?.company?.config?.menu?.presentacionNombresCategoriasMenu == 1,
                  'text-lowercase':parametersService?.company?.config?.menu?.presentacionNombresCategoriasMenu == 2,
                  'text-uppercase':parametersService?.company?.config?.menu?.presentacionNombresCategoriasMenu == 3}">{{item.nombre}}</span>
              <i *ngIf="item.hijas && item.hijas.length" class="fa fa-chevron-right"></i>
            </a>
            <ng-template #slugRoute>
              <a class="dropdown-menu-link d-flex align-items-center justify-content-between small"
                 [routerLink]="navigate(item)">
                    <span [ngClass]="{'text-capitalize':parametersService?.company?.config?.menu?.presentacionNombresCategoriasMenu == 1,
                    'text-lowercase':parametersService?.company?.config?.menu?.presentacionNombresCategoriasMenu == 2,
                    'text-uppercase':parametersService?.company?.config?.menu?.presentacionNombresCategoriasMenu == 3}">{{item.nombre}}</span>
                <i *ngIf="item.hijas && item.hijas.length" class="fa fa-chevron-right"></i>
              </a>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-template>
    <ul class="pl-0">
      <!-- Hijas -->
      <ng-template #recursiveList let-list>
        <li class="dropdown-menu-item" *ngFor="let item of list">
          <!-- [routerLink]="['/', item.slug, 'categories']" -->
          <a *ngIf="item?.url_destino; else slugRoute"
             class="dropdown-menu-link d-flex align-items-center justify-content-between small"
             [href]="item?.url_destino"
             [ngClass]="{'text-capitalize':parametersService?.company?.config?.menu?.presentacionNombresCategoriasMenu == 1,
                    'text-lowercase':parametersService?.company?.config?.menu?.presentacionNombresCategoriasMenu == 2,
                    'text-uppercase':parametersService?.company?.config?.menu?.presentacionNombresCategoriasMenu == 3}">
            {{item.nombre}}
            <i *ngIf="item.hijas && item.hijas.length" class="fa fa-chevron-right"></i>
          </a>
          <ng-template #slugRoute>
            <a class="dropdown-menu-link d-flex align-items-center justify-content-between small"
               [routerLink]="navigate(item)"
               [ngClass]="{'text-capitalize':parametersService?.company?.config?.menu?.presentacionNombresCategoriasMenu == 1,
                    'text-lowercase':parametersService?.company?.config?.menu?.presentacionNombresCategoriasMenu == 2,
                    'text-uppercase':parametersService?.company?.config?.menu?.presentacionNombresCategoriasMenu == 3}">
              {{item.nombre}}
              <i *ngIf="item.hijas && item.hijas.length" class="fa fa-chevron-right"></i>
            </a>
          </ng-template>
          <div class="dropdown-menu-child" *ngIf="item.hijas && item.hijas.length">
            <ul class="pl-0">
              <ng-container
                *ngTemplateOutlet="recursiveListChild; context:{ $implicit: item.hijas }"></ng-container>
            </ul>
          </div>
        </li>
      </ng-template>
      <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: menu.hijas }"></ng-container>
    </ul>
  </div>
</li>

<!-- MegaMenu Mobile -->
<nav class="menu-mobile d-block d-lg-none" role="navigation">
  <ng-template #recursiveMobileList let-list>
    <div *ngFor="let item of list">
      <a
        *ngIf="item?.url_destino; else movilSlugRoute"
        class="btn-menu d-block text-left ancla-decoration"
        role="button"
        [href]="item.url_destino" [ngClass]="{'text-capitalize':parametersService?.company?.config?.menu?.presentacionNombresCategoriasMenu == 1,
        'text-lowercase':parametersService?.company?.config?.menu?.presentacionNombresCategoriasMenu == 2,
        'text-uppercase':parametersService?.company?.config?.menu?.presentacionNombresCategoriasMenu == 3}">
        {{item.nombre}}
        <i *ngIf="item.hijas && item.hijas.length" class="fa fa-chevron-right"></i>
      </a>
      <ng-template #movilSlugRoute>
        <a [ngClass]="{'collapse-show':!item.expanded && item.hijas, 'text-capitalize':parametersService?.company?.config?.menu?.presentacionNombresCategoriasMenu == 1,
           'text-lowercase':parametersService?.company?.config?.menu?.presentacionNombresCategoriasMenu == 2,
           'text-uppercase':parametersService?.company?.config?.menu?.presentacionNombresCategoriasMenu == 3 }"
           class="btn-menu d-block text-left"
           role="button"
           (click)="menuAction(item)">
          {{item.nombre}}
          <i *ngIf="item.hijas && item.hijas.length" class="fa fa-chevron-right"></i>
        </a>
      </ng-template>
      <div [ngClass]="{'collapsing-1':item.expanded, 'collapsing-2':!item.expanded}"
           [hidden]="!item.expanded">
        <ng-container *ngTemplateOutlet="recursiveMobileList; context:{ $implicit: item.hijas }"></ng-container>
      </div>
    </div>
  </ng-template>
  <a *ngIf="menu?.url_destino; else movilSlugRoute"
     class="btn-menu d-block text-left text-white"
     role="button"
     [href]="menu.url_destino" [ngClass]="{'text-capitalize':parametersService?.company?.config?.menu?.presentacionNombresCategoriasMenu == 1,
        'text-lowercase':parametersService?.company?.config?.menu?.presentacionNombresCategoriasMenu == 2,
        'text-uppercase':parametersService?.company?.config?.menu?.presentacionNombresCategoriasMenu == 3}">
    <i class="icon-bag"></i> {{menu.nombre}}
  </a>

  <ng-template #movilSlugRoute>
    <a [ngClass]="{'collapse-item':menu.expanded && menu.hijas, 'collapse-show':!menu.expanded && menu.hijas, 'text-capitalize':parametersService?.company?.config?.menu?.presentacionNombresCategoriasMenu == 1,
           'text-lowercase':parametersService?.company?.config?.menu?.presentacionNombresCategoriasMenu == 2,
           'text-uppercase':parametersService?.company?.config?.menu?.presentacionNombresCategoriasMenu == 3 }"
       class="btn-menu d-block text-left text-white"
       role="button"
       (click)="menuAction(menu)">
      <i class="icon-bag"></i> {{menu.nombre}}
    </a>
  </ng-template>
  <div [ngClass]="{'collapsing-1':menu.expanded, 'collapsing-2':!menu.expanded}"
       [hidden]="!menu.expanded">
    <ng-container *ngTemplateOutlet="recursiveMobileList; context:{ $implicit: menu.hijas }">
    </ng-container>
  </div>
</nav>
