import {Component, OnInit, Input, ViewChild, TemplateRef, OnChanges, SimpleChanges, AfterViewInit} from '@angular/core';
import {LocationService, CoverageMode} from '@core/services/location/location.service';
import {CartService} from '@core/services/cart/cart.service';
import {filter, debounceTime, distinctUntilChanged, switchMap, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ParametersService} from '@core/services/parameters/parameters.service';
import {AuthService} from '@core/services/auth/auth.service';
import {MainService} from '@shared/services/main.service';
import {Router} from '@angular/router';
import { ToastService } from '@core/services/toast/toast.service';
import { IShoppingCartLocation, ShoppingCart } from '@core/models/cart.model';

@Component({
  selector: 'app-form-location',
  templateUrl: '../../../../templates/shared/components/form-location/form-location.component.html',
  styleUrls: ['../../../../templates/shared/components/form-location/form-location.component.scss'],
})
export class FormLocationComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('deliveryFormTemplate') deliveryFormTemplate: TemplateRef<any> = null;
  @ViewChild('addressCollectionTemplate') addressCollectionTemplate: TemplateRef<any> = null;
  @ViewChild('storePickupFormTemplate') storePickupFormTemplate: TemplateRef<any> = null;
  @ViewChild('deliveryOrPickupTemplate') deliveryOrPickupTemplate: TemplateRef<any> = null;
  @Input() public position: 'vertical' | 'horizontal' = 'vertical';
  @Input() public inModalContent = false;
  @Input() private option?: number;

  public previousTemplate: TemplateRef<any> = null;
  public principalTemplate: TemplateRef<any>;
  public city: any = null;
  public neighborhood: any = null;
  public coDefault: any;
  public location = {
    id: null,
    name: '',
    address: ''
  };
  public address = {
    streetname: 'Calle',
    street: '',
    corner: '',
    number: '',
    address: null,
    description: ''
  }

  constructor(
    public locationService: LocationService,
    public cartService: CartService,
    public authService: AuthService,
    public toastService: ToastService,
    private router: Router,
    private mainService: MainService,
    public parametersService: ParametersService
  ) {
  }

  ngOnInit() {

    this.cartService.shoppingCart$
      .pipe(
        distinctUntilChanged((prev: ShoppingCart, curr: ShoppingCart) => prev?.cartLocation === curr?.cartLocation)
      )
      .subscribe((response: ShoppingCart) => {
        
        this.parametersService.getSingleParamInStorage('lastPaymentLocation', null).subscribe((location: IShoppingCartLocation) => {

          const currentLocationInStorage = Object.assign({},location);

          if(
              (
                (!response?.cartLocation || response?.cartLocation == null)  || 
                (
                  (!response?.cartLocation?.countryId || response?.cartLocation?.countryId == null) &&
                  (!response?.cartLocation?.stateId || response?.cartLocation?.stateId == null) &&
                  (!response?.cartLocation?.cityId || response?.cartLocation?.cityId == null)
                ) || 
                response.error
              ) &&
              (
                currentLocationInStorage?.countryId > 0 && 
                currentLocationInStorage?.stateId > 0 &&
                currentLocationInStorage?.cityId > 0
              )
            ) {

            this.locationService.updateCartLocation(currentLocationInStorage).subscribe((response: any) => {
              
              if (!response.error) {
                this.cartService.updateShoppingCart(response.data.cardId || null);
                this.fetchAllDirections();
                // this.redirect();
              } else {
                // TODO: Show feedback
              }

            });
            
          }
          
        });
        
      });
  }

  ngAfterViewInit(): void {
    this.fetchStorePickUp();
    if (this.parametersService.page?.deliveryParams?.delivery_mode && this.parametersService.company?.config?.crearDireccionModalCobertura) {
      if (!this.authService.isAuthenticated) {
        // Establecer la ubicación
        this.cartService.shoppingCart$
          .pipe(filter((cart) => !cart.isCollectedInStore))
          .subscribe((cart) => {
            if (cart && cart.cartLocation && cart.cartLocation.cityId) {
              const {neighborhoodId, neighborhoodName, address} = cart.cartLocation;

              this.city = cart.cartLocation;
              this.neighborhood = {id: neighborhoodId, nombre: neighborhoodName};
              this.location.address = address;
              try {
                const indice = address.indexOf(' ');
                this.address.streetname = address.substring(0, indice);
                const indice2 = address.indexOf('#');
                this.address.street = address.substring(indice + 1, indice2);
                const indice3 = address.indexOf('-');
                this.address.corner = address.substring(indice2 + 1, indice3);
                this.address.number = address.substring(indice3 + 1, address.length);
              } catch (e) {
                console.log('error en la direccion');
              }
            } else {
              this.city = null;
              this.neighborhood = null;
              this.location.address = '';
            }
          });
      } else {
        this.fetchAllDirections();
      }
    }
    setTimeout(() => {
      this.principalTemplate = this.fetchTemplate();
    }, 10);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.option) {
      this.principalTemplate = this.fetchTemplate();
    }
  }

  nextTemplate(template: TemplateRef<any>) {
    this.previousTemplate = this.principalTemplate;
    this.principalTemplate = template;
  }

  backTemplate() {
    this.principalTemplate = this.previousTemplate;
    this.previousTemplate = null;
  }

  setFormAddress() {
    // const { neighborhoodId, neighborhoodName, address } = cartLocation;
    // this.city = cartLocation;
    // this.neighborhood = { id: neighborhoodId, nombre: neighborhoodName };
    // this.address = address;
  }

  fetchAllDirections() {
    this.locationService.getAllDirections().subscribe((directions) => {
      const locations = directions.data;
      this.locationService.locations = locations;
    });
  }

  fetchStorePickUp() {
    this.locationService.fetchPointsEnabledToPickUpInStore().subscribe((shops: any[]) => {
      this.locationService.collectionCoverage = shops;
      this.coDefault = this.validateOperatingCEnterDefault(shops);
      if (this.cartService.getCartId() === 'undefined') {
        this.mainService.setDeliveryLocation(this.coDefault.id).subscribe((response: any) => {
          this.cartService.updateShoppingCart(response.data.cardId || null);
        });
      }
    });
  }
  validateOperatingCEnterDefault(data) {

    let seleted = {};

    data.forEach(value => {
      if (value.pdv_por_defecto === '1') {
        seleted = value;
      }
    });

    return seleted;
  }
  clearlocation() {
    this.neighborhood = null;
    this.location.address = '';
  }

  // fetchCoverage() {
  //     this.locationService.getLocationCoverage().subscribe();
  // }

  fetchTemplate(): TemplateRef<any> {
    if (this.option) {
      switch (this.option) {
        case CoverageMode.pickup:
          return this.storePickupFormTemplate;
        case CoverageMode.deliveryList:
          return this.addressCollectionTemplate;
        case CoverageMode.deliveryOrPickup:
          return this.deliveryOrPickupTemplate;

        default:
          return this.deliveryFormTemplate;
      }
    }

    if (this.parametersService.page?.deliveryParams?.delivery_mode && this.parametersService.page?.deliveryParams?.store_pickup_mode) {
      return this.deliveryOrPickupTemplate;
    }

    if (this.authService.isAuthenticated && this.parametersService.page?.deliveryParams?.delivery_mode && this.parametersService.company?.config?.crearDireccionModalCobertura) {
      return this.addressCollectionTemplate;
    }

    if (this.parametersService.page?.deliveryParams?.store_pickup_mode) {
      return this.storePickupFormTemplate;
    }

    return this.deliveryFormTemplate;
  }

  searchCitiesByQuery = (query$: Observable<string>) =>
    query$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term => this.locationService.getCitiesByQuery(term))
    )

  searchNeighborhoodByQuery = (query$: Observable<string>) =>
    query$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term => this.locationService.getNeighborhoodByQuery(term, this.city?.cityId))
    )

  formatter = (x: { cityName?: string; nombre?: string; }) => x.cityName || x.nombre;

  isValid(): boolean {
    if (this.parametersService.page?.deliveryParams?.delivery_mode) {
      if (!this.parametersService.company?.config?.crearDireccionModalCobertura) {
        return !(this.city?.cityId);
      }
      return !(
        this.address.address
      );
    }

    return !(this.city?.cityId);
  }

  changeAddress(location: any) {

    // TODO: Cambiar la API de direcciones

    this.parametersService.setSingleParamInStorage('lastPaymentLocation', location);

    this.locationService.updateCartLocation(location).subscribe((response: any) => {
      if (!response.error) {
        this.cartService.updateShoppingCart(response.data.cardId || null);

        this.redirect();
      } else {
        // TODO: Show feedback
      }
    });
  }
  redirect() {
    if (this.cartService.shoppingCart.cartQuantity > 0) {
      this.router.navigate(['/order']);
    } else {
      window.location.reload();
    }
    this.previousTemplate = null;
    this.principalTemplate = this.fetchTemplate();

    this.locationService.closeLocationModal();
  }

  updateCartWithStorePickUpCoverage(id: string) {
    this.locationService.updateCartLocation({
      coverageLocation: id
    }).subscribe((response) => {
      if (!response.error) {
        this.cartService.updateShoppingCart(response.data.cardId || null);
        this.redirect();
      } else {
        // TODO: Show feedback
      }
    });
  }

  setCartLocation() {
    if (this.authService.isAuthenticated) {
      if (!this.parametersService.company?.config?.crearDireccionModalCobertura) {

        this.parametersService.setSingleParamInStorage('lastPaymentLocation', {
          ...this.city,
          neighborhoodId: this.neighborhood?.id,
          neighborhoodName: this.neighborhood?.nombre,
          address: this.location.address
        });

        this.locationService.updateCartLocation({
          ...this.city,
          neighborhoodId: this.neighborhood?.id,
          neighborhoodName: this.neighborhood?.nombre,
          address: this.location.address
        }).subscribe((response: any) => {
          if (!response.error) {
            this.cartService.updateShoppingCart(response.data.cardId || null);
            this.fetchAllDirections();
            this.redirect();
          } else {
            // TODO: Show feedback
          }
        });
      }else {
        this.locationService.createAddress({
          nombre: this.location.name || this.location.address,
          ciudadId: this.city?.cityId,
          barrioId: this.neighborhood?.id,
          direccion: this.location.address + (this.address?.description != '' ? ' - ' + this.address?.description : ''),
          usuarioId: this.authService.getUserId(),
          principal: 0
        }).subscribe((response: any) => {
          if (response.error) {
            this.toastService.error(response.message);
          } else {
            
            this.parametersService.setSingleParamInStorage('lastPaymentLocation', {
              ...this.city,
              neighborhoodId: this.neighborhood?.id,
              neighborhoodName: this.neighborhood?.nombre,
              address: this.location.address
            });

            this.locationService.updateCartLocation({
              ...this.city,
              neighborhoodId: this.neighborhood?.id,
              neighborhoodName: this.neighborhood?.nombre,
              address: this.location.address + (this.address?.description != '' ? ' - ' + this.address?.description : '')
            }).subscribe((response: any) => {
              if (!response.error) {
                this.cartService.updateShoppingCart(response.data.cardId || null);
                this.fetchAllDirections();
                this.redirect();
              } else {
                // TODO: Show feedback
              }
            });
          }
        });
      }
    } else {
      if (this.parametersService.company?.config?.crearDireccionModalCobertura) {
        if (!this.location.address) {
          return;
        }
        if (this.location.address === this.cartService.shoppingCart?.cartLocation?.address) {
          this.redirect();
        }
      } 

      
      this.parametersService.setSingleParamInStorage('lastPaymentLocation', {
        ...this.city,
        neighborhoodId: this.neighborhood?.id,
        neighborhoodName: this.neighborhood?.nombre,
        address: this.location.address
      });

      this.locationService.updateCartLocation({
        ...this.city,
        neighborhoodId: this.neighborhood?.id,
        neighborhoodName: this.neighborhood?.nombre,
        address: this.location.address + (this.address?.description != '' ? ' - ' + this.address?.description : '')
      }).subscribe((response: any) => {

        if (!response.error) {
          localStorage.setItem('cartId', response.data.cardId);
          this.cartService.updateShoppingCart(response.data.cardId || null);

          this.redirect();
        } else {
          // TODO: Show feedback

        }
      });
    }
  }
  setAddres() {
    if (this.address.corner && this.address.streetname && this.address.street && this.address.number) {
      this.address.address = this.address.streetname + ' ' + this.address.street + ' # ' + this.address.corner + ' - ' + this.address.number;
      this.location.address = this.address.address;
    }
  }
}
