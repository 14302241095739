import { Component, Output, EventEmitter, Input, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { ParametersService } from '@core/services/parameters/parameters.service';

class MegaMenuItem {
  id?: string;
  nombre: string; // TODO: API, se debe mantener la semántica
  disabled?: boolean;
  icon?: string;
  route?: string;
  slug?: string;
  url_destino?: string;
  hijas?: MegaMenuItem[]; // TODO: API, se debe mantener la semántica
  expanded = false;
}

@Component({
  selector: 'app-mega-menu',
  templateUrl: '../../../../templates/shared/components/mega-menu/mega-menu.component.html',
  styleUrls: ['../../../../templates/shared/components/mega-menu/mega-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MegaMenuComponent {
  @Input() menu: MegaMenuItem;
  @Input() promocion: boolean;
  @Input() footer: boolean;
  @Output() closeMenu = new EventEmitter();

  constructor(
    private router: Router,
    public parametersService: ParametersService,
  ) { }

  menuAction(item: MegaMenuItem) {
    if (item?.hijas && item?.hijas?.length > 0) {
      return item.expanded = !item.expanded;
    }

    // this.router.navigate(['/', item.slug, 'categories']);
    this.router.navigate(this.navigate(item));
    this.closeMenu.emit();
  }

  navigate(item: MegaMenuItem) {
    if (item?.hijas?.length > 0) {
      return ['/', item?.slug];
    }

    return ['/', item?.slug, 'products'];
  }
}
