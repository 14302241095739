import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-browser-not-supported',
  templateUrl: '../../templates/browser-not-supported/browser-not-supported.component.html',
  styleUrls: ['../../templates/browser-not-supported/browser-not-supported.component.scss']
})
export class BrowserNotSupportedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
