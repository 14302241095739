<!-- Rate Your Experience -->
<h4 class="title-section h1 text-center">
  Califica tu experiencia
</h4>
<p class="text-center" *ngIf="!alreadySend">
  Cuéntanos como te pareció la experiencia de compra en nuestra tienda virtual
</p>
<p class="text-center text-success" *ngIf="alreadySend">
  Gracias por haber calificado tu experiencia de compra, tu opinión es muy importante para nosotros
</p>
<div *ngIf="!alreadySend">
  <div class="row">
    <div class="col-12 offset-lg-4 col-lg-4">
      <textarea name="comment"
        #inputCommentary
        rows="4"
        class="form-control mb-3"
        placeholder="Añadir comentario"
        maxlength="500"></textarea>
      <div class="d-flex flex-column align-items-center">
        <p class="text-center m-0">{{500 - inputCommentary.value.length}} carácteres restantes</p>
        <p class="text-center m-0">Seleccione el número de estrellas de 1 a 5</p>
        <ngb-rating class="my-3" #rating [max]="5"></ngb-rating>
      </div>
      <button class="btn text-uppercase small" *ngIf="!alreadySend" type="submit" (click)="experienceRequest(inputCommentary.value, rating.rate || 0)">
        Calificar
      </button>      
    </div>
  </div>
</div>
