<!-- Breadcrumb -->
<nav aria-label="breadcrumb">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ol class="breadcrumb small px-0 bg-transparent">
          <li class="breadcrumb-item"><a [routerLink]="['/']"><i
            class="icon icon-home"></i></a></li>
          <li class="breadcrumb-item active">
            <span class="w700">
              Comparar Productos
            </span>
          </li>
        </ol>
      </div>
    </div>
  </div>
</nav>
<!-- Compare Products -->
<div class="compare-products-container">
  <div class="container">
    <!-- Header -->
    <div class="row">
      <div class="col-12">
        <h1 class="title-section text-center">
          Comparar Productos
        </h1>
        <hr>
      </div>
    </div>

    <!-- Alert -->
    <ng-template #notProducts>
      <div class="alert alert-warning alert-dismissable small text-center d-none">
        <button aria-hidden="true" data-dismiss="alert" class="close" type="button"> ×</button>
        <strong>Nota: </strong> No hay productos para comparar.
        <a class="alert-link" [href]="parametersService?.company.app.header.logo.url">Ir al inicio</a>.
      </div>
    </ng-template>
    <!-- Content -->
    <div class="row" *ngIf="productsToCompare; else notProducts">
      <!-- Compare Products Cards -->
      <div class="col-12 col-md-6 col-lg-3 mb-5" *ngFor="let productCompare of productsToCompare">
        <div class="card">
          <!-- Image -->
          <img class="card-img-top img-fluid"
               [src]="productCompare.imagesDetail[0].path+productCompare.imagesDetail[0].image"
               alt="{{productCompare.name}}">
          <div class="card-body">
            <!-- Product Name -->
            <div class="card-title">
              <a class="card-link" [routerLink]="['/',productCompare.category.slug, productCompare.slug]">
                <p class="compare-products-title text-left text-uppercase mb-1">
                  {{productCompare.name}}
                </p>
              </a>
            </div>
            <!-- Product Info -->
            <div class="card-text small mb-3">
              <!-- Reference -->
              <span class="w700">Referencia:</span> {{productCompare.reference}}<br>
              <!-- ERP Code -->
              <div *ngIf="productCompare.erpCode">
                <span class="w700">Código:</span> {{productCompare.erpCode}}<br>
              </div>
              <!-- Prices -->
              <div *ngIf="productCompare.showPrices">
                <div *ngIf="productCompare.currentPrice !== null">
                  <span *ngIf="!productCompare.visualExcludedIva">
                    <span class="w700">Precio:</span> $ {{productCompare.currentPrice | number : '1.0-0'}}
                  </span>
                  <span *ngIf="productCompare.visualExcludedIva">
                    <span
                      class="w700">Precio:</span> $ {{(productCompare.currentPrice - productCompare.taxValue) | number : '1.0-0'}}
                  </span>
                  {{productCompare.rate}}
                  <i class="fa fa-info-circle small text-muted" data-placement="top"
                     [ngbTooltip]="productCompare.taxText" data-container="body"></i>
                  <br>
                </div>
                <div *ngIf="!productCompare.showPrices">
                  <span class="w700">Precio:</span> $-
                </div>
                <div *ngIf="productCompare.category">
                  <span class="w700">Categoria:</span>
                  <a [routerLink]="'/' + productCompare.category.slugCompare" [title]="productCompare.category.title">
                    {{productCompare.category.title}}
                  </a>
                </div>
                <!-- Short Description -->
                <div class="my-2" [innerHTML]="productCompare.shortDescription"></div>
              </div>
            </div>
            <!-- Actions -->
            <button *ngIf="productCompare.showProductAction" data-target=".precanasta-cantidad"
                    (click)="AddToCart(productCompare)"
                    class="btn btn-block btn-primary">
              <i class="icon icon-basket"></i> Añadir a la Canasta
            </button>
            <button *ngIf="!productCompare.showProductAction"
                    [routerLink]="['/',productCompare.category.slug, productCompare.slug]"
                    class="btn btn-block btn-secondary">
              <i class="icon icon-magnifier-add"></i> Ver Más
            </button>
            <button (click)="delProductToArray(productCompare.id)"
                    class="btn btn-block btn-danger">
              <i class="icon-close icons"></i> Eliminar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-2 mb-5">
      <button (click)="backClicked()"
              class="btn btn-block btn-primary">
        Regresar
      </button>
    </div>
  </div>
</div>
