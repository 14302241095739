<!-- Breadcrumb -->
<nav class="breadcrumb bg-transparent mb-4 p-0 small">
  <ul class="breadcrumb-list list-inline d-flex mb-0">
    <li class="breadcrumb-list-item list-inline-item mr-0"><a class="breadcrumb-list-link" [routerLink]="['/']">Home</a></li>
    <li class="breadcrumb-list-item list-inline-item mr-0"><a class="breadcrumb-list-link" [routerLink]="['/','blog']">Blog</a></li>
    <li class="breadcrumb-list-item list-inline-item mr-0">{{ blogCategory.category }}</li>
  </ul>
</nav>

<!-- Blog -->
<div class="blog-container">
  <div class="container">
    <!-- Header -->
    <div class="row">
      <div class="col-12">
        <h1 class="title-section text-center">
          Categoría<span *ngIf="blogCategory">: {{ blogCategory.category }}</span>
        </h1>
      </div>
    </div>
    <!-- ADS -->
    <div class="row" *ngIf="cmsData">
      <div class="col-12 mb-3">
        <app-advertisements *ngIf="cmsData.espacios[1]" [typeContent]="'espacios'" [positions]="1" [contentData]="cmsData"></app-advertisements>
      </div>
    </div>
    <div class="row">
      <section class="col-12 col-lg-9">
        <div class="post" *ngIf="blogCategory">
          <div class="row" *ngFor="let blog of blogCategory.posts">
            <div class="col-12 col-lg-4 mb-3">
              <a [routerLink]="['/blog',blog.slug]">
                <img src="{{ blog.imagen }}" class="img-post img-fluid" alt="">
              </a>
            </div>
            <div class="col-12 col-lg-8 mb-3">
              <div *ngIf="blog.categories">
                <div class="badge" *ngFor="let categories of blog.categories">
                  <a class="btn btn-sm btn-secondary"
                     [routerLink]="['/blog/category/',categories.slug]">
                    {{ categories.nombre }}
                  </a>
                </div>
              </div>
              <a [routerLink]="['/blog',blog.slug]">
                <h5 class="w700">
                  {{ blog.nombre }}
                </h5>
              </a>
              <p class="text-justify">
                {{ blog.descripcion }}
              </p>
              <button class="btn btn-primary" [routerLink]="['/blog',blog.slug]">
                Ver Noticia
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex justify-content-center my-3">
              <!-- Component: Pagination -->
              <ngb-pagination [(page)]="numerPage" [pageSize]="limitPage" [collectionSize]="totalBlog" [maxSize]="3"
                              (pageChange)="handlePageChange($event)"></ngb-pagination>
            </div>
          </div>
        </div>
      </section>
      <aside class="col-12 col-lg-3">
        <!-- Component: Aside Blog -->
        <app-aside-blog [cmsData]="cmsData" [cmsType]="'espacios'" [cmsPosition]=2></app-aside-blog>
      </aside>
    </div>
  </div>
</div>
