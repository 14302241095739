import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: '../../../../templates/shared/components/breadcrumb/breadcrumb.component.html',
    styleUrls: ['../../../../templates/shared/components/breadcrumb/breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}
