<!-- Breadcrumb -->
<nav aria-label="breadcrumb">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ol class="breadcrumb small px-0 bg-transparent">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="icon-home"></i>
            </a>
          </li>
          <li class="breadcrumb-item active">
            <span class="w700">
              404
            </span>
          </li>
        </ol>
      </div>
    </div>
  </div>
</nav>
<!-- 404 -->
<div class="4040-container">
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex flex-column align-items-center my-5">
        <img src="../../assets/images/404.png" class="img-fluid mb-3">
        <h1 class="text-center">
          <span class="w700">Ooops!</span> Página no encontrada.
        </h1>
        <a [routerLink]="['/']">Ir al inicio</a>
      </div>
    </div>
  </div>
</div>
