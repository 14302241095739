<!-- Quantity-->
<div class="quantity">
  <!-- Minus Button -->
  <button class="quantity-button"
    type="button"
    [disabled]="minimumAvailable() || (updatingQuantity && updatingQuantityIdItem == idItem)"
    (click)="minusQuantity()">
    <h6 class="m-0">-</h6>
  </button>
  <!-- Input -->
  <input class="quantity-input form-control small"
    type="text"
    *ngIf="!updatingQuantity || updatingQuantityIdItem != idItem"
    [formControl]="inputQuantityControl"
    #inputNumber
    appOnlyNumbers>
  <div *ngIf="updatingQuantity && updatingQuantityIdItem == idItem" class="spinner-border text-info" role="status">
    <span class="sr-only"></span>
  </div>
  <!-- Plus Button -->
  <button class="quantity-button"
    type="button"
    [disabled]="updatingQuantity && updatingQuantityIdItem == idItem"
    (click)="plusQuantity()">
    <h6 class="m-0">+</h6>
  </button>
</div>