<!-- Modal: Remover producto de canasta -->
<ng-template #removeItemModal let-product='data'>
  <p class="mb-0">
    ¿Deseas eliminar el producto: <span class="w700">{{product.name}}</span>?
  </p>
</ng-template>

<ng-container *ngIf="!switchSpinnerService.state">

  <!-- Breadcrumb -->
  <nav class="breadcrumb bg-transparent mb-4 p-0 small">
    <ul class="breadcrumb-list list-inline d-flex mb-0">
      <li class="breadcrumb-list-item list-inline-item mr-0"><a class="breadcrumb-list-link" [routerLink]="['/']">Home</a></li>
      <li class="breadcrumb-list-item list-inline-item mr-0">Canasta</li>
    </ul>
  </nav>

  <!--Shopping Cart-->
  <div class="shopping-cart-container">
    <div class="container">
      <!-- shopping Cart Empty --->
      <ng-template #emptyShoppingCart>
        <div class="row">
          <div class="col-12 mb-5 d-flex flex-column align-items-center">
            <div class="mb-5">
              <i class="lni lni-shopping-basket icon-lg"></i>
            </div>
            <h2 class="title-section text-center">
              Canasta Vacía
            </h2>
            <p>Lo invitamos a ver nuestro catálogo que cuenta con productos de excelente calidad.</p>
            <a [routerLink]="['/']">Ir al inicio</a>
          </div>
        </div>
      </ng-template>
      <!-- shopping Cart Full --->
      <div *ngIf="(cartService.shoppingCart$ | async) as shoppingCart; else emptyShoppingCart">
        <div class="row" *ngIf="(shoppingCart.itemsCanastaUsuario && shoppingCart.itemsCanastaUsuario.length); else emptyShoppingCart">
          <div class="col-12">
            <app-advertisements *ngIf="cmsData" [typeContent]="'espacios'" [positions]="3" [contentData]="cmsData"></app-advertisements>
          </div>
          <div class="col-12">            
            <h1 class="title-section text-center">Canasta</h1>
          </div>
          <div class="col-12 col-lg-9">
            <!-- Order Mobile-->
            <section class="order-mobile d-block d-lg-none">
              <div class="card mb-3" *ngFor="let item of (shoppingCart.itemsCanastaUsuario || [])">
                <div class="card-body">
                  <div class="row">
                    <div class="col-5">
                      <div class="item-image">
                        <img class="img-fluid" [src]="item.image" [alt]="item.name">
                      </div>
                    </div>
                    <div class="col-7">
                      <div class="delete-item">
                        <button type="button" class="close"
                                (click)="showModalRemoveItemToShoppingCart(removeItemModal, item)">
                          <i class="material-icons-outlined" aria-hidden="true">
                            close
                          </i>
                        </button>
                      </div>
                      <div class="item-name">
                        <a class="item-name-link" [routerLink]="['/', item.category.slug, item.slug]">
                          <p class="item-name-text w700">{{item.name}}</p>
                        </a>
                      </div>
                      <div class="item-quantity mb-2">
                        <app-quantity
                          [quantity]="item.quantity"
                          [min]="item.minQuantity &&  item.minQuantity > 0 ? item.minQuantity : 1"
                          [factor]="item.factorQuantity &&  item.factorQuantity > 0 ? item.factorQuantity : 1"
                          (changeQuantity)="setQuantityToItem(item, $event)"
                          [updatingQuantity]="updatingQuantity"
                          [idItem]="item.id"
                          [updatingQuantityIdItem]="updatingQuantityIdItem">
                        </app-quantity>
                      </div>
                      <div class="item-resume">
                        <small>
                          <span class="w700">Ref.:</span> {{item.reference}}<br>
                          <span class="w700">Vr. Unit.:</span> $ {{item.price | number : '1.0-0'}}<br>
                          <div *ngFor="let atributoClon of (item.attributesDetail || [])">
                            <span class="w700">{{ atributoClon.title}}: </span> {{ atributoClon.value[0] }}
                          </div>
                          <span class="w700">Dscto.:</span> $ {{item.discount | number: '1.0-0'}}<br>
                          <span class="w700">Impuesto:</span> {{item.iva | number: '1.0-2'}} % <br>
                          <span class="w700">Subtotal:</span> $ {{item.total | number : '1.0-0'}}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- Order Desktop-->
            <section class="order table-responsive d-none d-lg-block">
              <table class="table">
                <thead class="h6 small text-uppercase">
                <tr>
                  <th scope="col" width="10%"></th>
                  <th scope="col" width="20%">Producto</th>
                  <th scope="col" width="25%">Cantidad</th>
                  <th scope="col" class="text-center">Vr. Unit.</th>
                  <th scope="col" class="text-center">Dscto</th>
                  <th scope="col" class="text-center" *ngIf="parametersService.company?.config?.data?.cart?.showDiscountCoupon">D. x cupón</th>
                  <th scope="col" class="text-center">Impuesto</th>
                  <th scope="col" class="text-center">Subtotal</th>
                </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="9" class="p-0">
                      <section class="body-table">
                        <table>
                          <tbody>
                            <tr *ngFor="let item of (shoppingCart.itemsCanastaUsuario || [])">
                              <!-- Remove Action -->
                              <td class="pr-1" width="1%">
                                <button type="button"
                                  class="button-delete close"
                                  placement="top"
                                  ngbTooltip="Eliminar ítem de la canasta"
                                  (click)="showModalRemoveItemToShoppingCart(removeItemModal, item)">
                                  <i-feather class="button-delete-icon" name="trash-2"></i-feather>
                                </button>
                              </td>
                              <!-- Image -->
                              <td class="px-0" width="8%">
                                <a [routerLink]="['/', item.category.slug, item.slug]">
                                  <img [src]="item.image" class="product-image img-fluid" width="64" [alt]="item.name">
                                </a>
                              </td>
                              <!-- Name & Info -->
                              <td class="pl-0" width="10%">
                                <p class="item-text small mb-1">
                                  <a class="item-text-link" [routerLink]="['/', item.category.slug, item.slug]">{{item.name}}</a>
                                </p>
                                <p class="item-text small"><span class="small">Ref: </span>{{item.reference}}</p>
                                <p class="item-texsmallt" *ngIf="parametersService.page?.mostrarUnidadMedidaItemCanasta">
                                  <span class="small">U. Medida: </span>{{item.measureUnit}}
                                </p>
                                <p class="small" *ngFor="let atributoClon of (item.attributesDetail || [])">
                                  <span class="small">{{ atributoClon.title}}: </span> {{ atributoClon.value[0] }}
                                </p>
                              </td>
                              <!-- Quantity -->
                              <td width="10%" class="pl-0">
                                <app-quantity
                                  [quantity]="item.quantity"
                                  [min]="item.minQuantity &&  item.minQuantity > 0 ? item.minQuantity : 1"
                                  [factor]="item.factorQuantity &&  item.factorQuantity > 0 ? item.factorQuantity : 1"
                                  (changeQuantity)="setQuantityToItem(item, $event)"
                                  [updatingQuantity]="updatingQuantity"
                                  [idItem]="item.id"
                                  [updatingQuantityIdItem]="updatingQuantityIdItem">
                                </app-quantity>
                              </td>
                              <!-- Value -->
                              <td class="text-center">$ {{item.price | number : '1.0-0'}}</td>
                              <!-- Dcto -->
                              <td class="text-center">$ {{item?.cuponCode ? '0' : item.discount | number: '1.0-0' }}</td>
                              <!-- Dcto x Cupon -->
                              <td class="text-center" *ngIf="parametersService.company?.config?.data?.cart?.showDiscountCoupon">
                                $ {{!item?.cuponCode ? '0' : item.discount | number: '1.0-0' }}
                              </td>
                              <!-- IVA -->
                              <td class="text-center">{{item.iva}} %</td>
                              <!-- Subtotal -->
                              <td class="text-center text-secondary">$ {{item.total | number : '1.0-0'}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </section>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
            <!-- Order comment -->
            <section class="order-comment">
              <blockquote class="blockquote p-2" *ngIf="shoppingCart?.cartOrderData?.comment">
                <p class="small mb-0"><span class="w700">Comentario:</span> "{{shoppingCart.cartOrderData.comment}}"</p>
              </blockquote>
              <div class="row">
                <div class="col-lg-auto col-12 mb-lg-0 mb-3">
                  <!-- Formulario de cupon -->
                  <form #discountCupon="ngForm" (ngSubmit)="saveDiscountCoupon(couponCode)"
                        id="discountCupon-form"
                        novalidate>
                    <div class="row no-gutters">
                      <div class="form-group col-auto mb-0"
                           *ngIf="parametersService.company?.config?.data?.cart?.showDiscountCoupon">
                        <p *ngIf="savingCoupon" class="mb-0 ng-hide">
                          <i ng-class="{'fa fa-history load-animation': savingCoupon, 'fa fa-history': !savingCoupon}"
                             class="fa fa-history"></i>
                        </p>
                        <input type="text" id="couponCode"
                               *ngIf="!validCoupon && !shoppingCart.cartCoupons"
                               class="form-control" name="couponCode"
                               [disabled]="savingCoupon"
                               #couponDiscounCode name="couponCode" placeholder="Cupón de descuento"
                               [(ngModel)]="couponCode">
                      </div>
                      <div class="form-group col mb-0"
                           *ngIf="parametersService.company?.config?.data?.cart?.showDiscountCoupon">
                        <button type="button" class="btn btn-block btn-outline-primary"
                                *ngIf="!validCoupon && !shoppingCart.cartCoupons"
                                [disabled]="savingCoupon"
                                (click)="saveDiscountCoupon(couponCode)">
                          Aplicar
                        </button>
                      </div>
                      <div class="form-group mb-0 col-12"
                           *ngIf="validCoupon || couponSaved && !validCoupon || shoppingCart.cartCoupons">
                        <div class="alert alert-success" *ngIf="validCoupon">
                          <div ng-repeat="itemDescuento in couponResponse['respuestaItem']">
                            <div ng-bind-html="itemDescuento.mensaje | toTrusted"></div>
                          </div>
                          {{couponResponse['message']}}
                        </div>
                        <div class="alert alert-danger" *ngIf="couponSaved && !validCoupon">
                          <div ng-repeat="itemDescuento in couponResponse['respuestaItem']">
                            <div ng-bind-html="itemDescuento.mensaje | toTrusted"></div>
                          </div>
                          {{couponResponse['message']}}
                        </div>
                        <div class="listado-cupones ng-scope" *ngIf="shoppingCart.cartCoupons">
                        <span class="ng-binding ng-scope">
                          {{shoppingCart?.cartCoupons}}
                          <i class="fas fa-trash" title="Eliminar Cupón de descuento"
                             (click)="deleteCoupon(shoppingCart?.cartCoupons);"></i>
                        </span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-lg-12 col-12 d-flex justify-content-end">
                  <button type="button"
                    class="btn btn-block btn-outline-primary"
                    [hidden]="inputOrderComment.value === shoppingCart?.cartOrderData?.comment"
                    (click)="setOrderComment(inputOrderComment.value)"
                    *ngIf="toggleOrderComment">
                    <i class="fas fa-save"></i>
                    Guardar
                  </button>
                  <button type="button"
                    class="btn btn-block small text-uppercase"
                    [ngClass]="{ 'btn': !toggleOrderComment, 'btn-danger': toggleOrderComment }"
                    (click)="toggleOrderComment = !toggleOrderComment">
                    <i-feather class="mr-2" name="edit-3"></i-feather> <small>{{ toggleOrderComment ? 'Cancelar' : 'Editar Comentario'}}</small>
                  </button>
                </div>
              </div>
              <div class="form-group my-3" [hidden]="!toggleOrderComment">
                <small class="form-text text-muted mb-3">
                  En el siguiente recuadro puedes ingresar un comentario abierto para tu pedido: (Máximo 150 caracteres)
                </small>
                <textarea #inputOrderComment (blur)="setOrderComment(inputOrderComment.value)"
                          [value]="shoppingCart?.cartOrderData?.comment"
                          class="form-control mb-3" placeholder="Escriba aquí su comentario..."
                          maxlength="150"
                          rows="4"></textarea>
                <small class="d-block text-right" [ngClass]="{
                    'text-muted': inputOrderComment.value.length === 0,
                    'text-success': (inputOrderComment.value.length > 0 && inputOrderComment.value.length < 100),
                    'text-warning': inputOrderComment.value.length >= 100 && inputOrderComment.value.length < 150,
                    'text-danger': inputOrderComment.value.length === 150}">
                  {{ inputOrderComment.value.length }} / 150
                </small>
              </div>
              <!-- End Código de Descuento -->
              <!-- ADS -->
              <div class="mt-4" [innerHTML]="parametersService.page?.cmsInfo?.CANASTA_INFO_INFERIOR"></div>
            </section>
          </div>
          <!-- Order details -->
          <div class="col-12 col-lg-3">
            <section class="position-sticky">
              <!-- Shopping Cart Information section -->
              <div class="card mb-4">
                <div class="card-header justify-content-center">
                  <h6 class="card-title w700 mb-0 text-uppercase">
                    Resumen
                  </h6>
                </div>
                <div class="card-body">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      <span class="w700">Subtotal</span>
                      <span class="text-secondary">${{shoppingCart.cartSubtotal | number : '1.0-0'}}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      <span class="w700">Descuentos</span>
                      <span class="text-secondary">${{shoppingCart.cartDiscounts | number : '1.0-0'}}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center"
                        *ngIf="shoppingCart?.cartCoupons && parametersService.company?.config?.data?.cart?.showDiscountCoupon">
                      <span class="w700">Dcto. x cupón</span>
                      <span class="text-secondary">${{shoppingCart.cartCouponDiscounts | number : '1.0-0'}}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      <span class="w700">Impuestos</span>
                      <span class="text-secondary">${{shoppingCart.cartTaxes | number : '1.0-0'}}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center"
                        *ngIf="shoppingCart.valueTaxBag > 0 && shoppingCart.useBagTax">
                      <span class="w700">Impuesto a la bolsa</span>
                      <span class="text-secondary">${{shoppingCart.valueTaxBag | number : '1.0-0'}}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-center align-items-center bg-light"
                      *ngIf="shoppingCart.valueTaxBag > 0 && shoppingCart.useBagTax && shoppingCart.mensaje_impuesto_bolsa !=''">
                      <p class="mb-0 small text-center">{{shoppingCart.mensaje_impuesto_bolsa}}</p>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      <span class="w700">Subtotal Neto</span>
                      <span class="text-secondary">${{((shoppingCart.cartSubtotal - shoppingCart.cartDiscounts - shoppingCart.cartCouponDiscounts) + shoppingCart.cartTaxes + shoppingCart.valueTaxBag) | number : '1.0-0'}}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center"
                        *ngIf="(parametersService.company?.config?.data?.cart?.showTransportPrice || parametersService?.company?.config?.debug) && shoppingCart?.cartTransports">
                      <span class="w700">Transporte</span>
                      <span class="text-secondary">${{shoppingCart.cartTransports | number : '1.0-0'}}</span>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center font-weight-bold">
                      <span class="w700">Total a pagar</span>
                      <span class="text-secondary h5 m-0">${{shoppingCart.cartTotal | number: '1.0-0' }}</span>
                    </li>
                    <li
                      *ngIf="shoppingCart.showTransportMessage == 1 && shoppingCart.cartTransports <= 0"
                      class="list-group-item d-flex justify-content-between align-items-center font-weight-bold text-danger">
                      <span class="w700">{{ shoppingCart.transportMessage }}</span>
                    </li>
                  </ul>
                </div>
              </div>

              <!-- gift Form -->
              <div
                *ngIf="parametersService?.page?.showGiftForm"
                class="card mb-3">
                <div class="card-header">
                  <h6 class="card-title mb-0 w700">
                    <i class="fas fa-gift"></i>
                    {{ parametersService?.page?.giftFormLabel }}
                  </h6>
                </div>
                <div class="card-body">
                  <div
                    class="" style="display:flex;flex-direction:column;"
                    *ngIf="(parametersService.custom$ | async) as customParameters; else emptyCustomParam">
                    <div
                      *ngIf="customParameters?.de"
                      class="">
                      <span class="w700">De: </span>
                      <span>{{ customParameters?.de | ellipsis:30 }}</span>
                    </div>
                    <div
                      *ngIf="customParameters?.para"
                      class="">
                      <span class="w700">Para: </span>
                      <span>{{ customParameters?.para | ellipsis:30 }}</span>
                    </div>
                    <div
                      *ngIf="customParameters?.mensaje"
                      class="">
                      <span class="w700">Mensaje: </span>
                      <span>{{ customParameters?.mensaje | ellipsis:45 }}</span>
                    </div>
                  </div>
                  <ng-template #emptyCustomParam>
                    <div
                      class="" style="display:flex;flex-direction:column;"
                      *ngIf="customProperties">
                      <div
                        *ngIf="customProperties?.de"
                        class="">
                        <span class="w700">De: </span>
                        <span>{{ customProperties?.de | ellipsis:30 }}</span>
                      </div>
                      <div
                        *ngIf="customProperties?.para"
                        class="">
                        <span class="w700">Para: </span>
                        <span>{{ customProperties?.para | ellipsis:30 }}</span>
                      </div>
                      <div
                        *ngIf="customProperties?.mensaje"
                        class="">
                        <span class="w700">Mensaje: </span>
                        <span>{{ customProperties?.mensaje | ellipsis:45 }}</span>
                      </div>
                    </div>
                  </ng-template>
                  <div
                    class="mt-1">
                    <button type="button" class="btn btn-block btn-primary"
                            (click)="locationService.openCustomModal()">
                      Editar Información
                    </button>
                  </div>
                </div>
              </div>
              <!-- gift Form -->

              <!-- Location section -->
              <div class="card mb-4">
                <div class="card-header justify-content-center">
                  <h6 class="card-title w700 mb-0 text-uppercase">Destino</h6>
                </div>
                <div class="card-body">
                  <div *ngIf="(
                    parametersService.page?.showLocationQuestion || (parametersService.page?.deliveryParams?.delivery_mode || parametersService.page?.deliveryParams?.store_pickup_mode)
                    ); else searchLocation">
                    <p class="text-center" [innerHTML]="locationInformation()"></p>
                    <button type="button" class="btn btn-block btn-secondary small text-uppercase"
                      (click)="ngxSmartModalService.getModal(locationService.modalReference).toggle()">
                      <small><i-feather class="icon-button" name="map-pin"></i-feather> {{shoppingCart.cartLocation.cityId ? 'Cambiar' : 'Seleccione'}} Dirección</small>
                    </button>
                  </div>
                  <ng-template #searchLocation>
                    <div class="form-group small">
                      <label>Digita la ciudad de destino</label>
                      <app-box-search
                        [results$]="locations$"
                        [searching]="searching"
                        [placeholder]="shoppingCart?.cartOrderData?.location?.fullname"
                        (handleInput)="searchLocations($event)"
                        (handleSelect)="selectLocation($event)">
                      </app-box-search>
                    </div>
                  </ng-template>
                </div>
              </div>
              <!--total weight of the basket -->
              <div class="card mb-3" *ngIf="parametersService.company?.config?.data?.cart?.showCartWeight">
                <div class="card-header">
                  <h6 class="card-title w700 mb-0">
                    <i class="fas fa-balance-scale"></i> Peso total del pedido
                  </h6>
                </div>
                <ul class="list-group list-group-flush small">
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span class="w700">Total peso:</span>
                    <span>{{shoppingCart.cartWeight}} KL</span>
                  </li>
                </ul>
              </div>
              <!-- Payment Means Section -->
              <div class="card mb-0" *ngIf="paymentMethods">
                <div class="card-header">
                  <h6 class="card-title mb-0 w700">
                    <i class="fas fa-credit-card"></i> Medio de Pago
                  </h6>
                </div>
                <div class="card-body">
                  <label for="means-of-payment" [ngClass]="{ 'text-danger': shoppingCart?.cartOrderData?.payment?.id === null }">
                    Seleccione su medio de pago
                  </label>
                  <select #inputPaymentMethods class="custom-select payment-control" id="means-of-payment"
                    [value]="shoppingCart?.cartOrderData?.payment.id"
                    (change)="setPaymentMethod(+inputPaymentMethods.value)">
                    <option value="" *ngIf="shoppingCart?.cartOrderData?.payment?.id === null">Seleccione...</option>
                    <option *ngFor="let payment of paymentMethods" [value]="payment.id"
                      [selected]="shoppingCart?.cartOrderData?.payment?.id === payment.id">
                      {{payment.nombre}}
                    </option>
                  </select>
                </div>
              </div>
              <!-- Actions Section -->
              <div class="form-group d-none d-lg-block mb-5">
                <button class="confirm-order btn btn-block btn-green small text-uppercase" (click)="saveOrder()">
                  {{parametersService.company?.config?.data?.cart?.cartBuyText}}
                </button>
                <button class="btn btn-block btn-red small text-uppercase" (click)="discardShoppingCart()">
                  Descartar Pedido
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="confirm-order w-100 d-block d-lg-none" *ngIf="(cartService.shoppingCart$ | async)?.itemsCanastaUsuario?.length > 0">
    <div class="row no-gutters">
      <div class="col-6">
        <button class="btn btn-block btn-red small text-uppercase" (click)="discardShoppingCart()">Descartar Pedido</button>
      </div>
      <div class="col-6">
        <button class="btn btn-block btn-primary text-uppercase" (click)="saveOrder()">
          {{parametersService.company?.config?.data?.cart?.cartBuyText}}
        </button>
      </div>
    </div>
  </div>
</ng-container>