<!-- Breadcrumb -->
<nav class="breadcrumb bg-transparent mb-4 p-0 small">
  <ul class="breadcrumb-list list-inline d-flex mb-0">
    <li class="breadcrumb-list-item list-inline-item mr-0"><a class="breadcrumb-list-link" [routerLink]="['/']">Home</a></li>
    <li class="breadcrumb-list-item list-inline-item mr-0">Sedes</li>
  </ul>
</nav>

<!-- Headquarters -->
<div class="headquarters-container">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="title-section text-center">
          Sedes
        </h1>
        <div class="row">
          <div class="headquarters col-12 mb-3" *ngFor="let city of headquarters">
            <!-- Title -->
            <div class="headquarters-title mb-3">
              <h4 class="w700 p-3">
                {{"Sede " + city.name}}
              </h4>
            </div>
            <div class="row">
              <div class="col-12 col-md-6 col-lg-4 mb-3" *ngFor="let sede of city.sedes">
                <div class="card">
                  <!-- Image -->
                  <a [routerLink]="['/', 'headquarters', sede.id]">
                    <img class="card-img-top img-fluid" [src]="sede.image">
                  </a>
                  <div class="card-body card-custom">
                    <!-- Title -->
                    <div class="card-title mb-0">
                      <a class="card-link" [routerLink]="['/', 'headquarters', sede.id]">
                        <p class="text-left text-uppercase w700 mb-0">
                          {{sede.name}}
                        </p>
                      </a>
                    </div>
                  </div>
                  <!-- Info -->
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <i class="icon-phone mr-1"></i>{{sede.PBX}}
                    </li>
                    <li class="list-group-item">
                      <i class="icon-location-pin mr-1"></i>{{sede.address}}
                    </li>
                  </ul>
                </div>
                <!-- Action -->
                <a class="btn btn-primary btn-block mt-3"
                   [routerLink]="['/', 'headquarters', sede.id]">
                  <i class="icon-magnifier-add mr5" aria-hidden="true"></i> Ver Sede
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
