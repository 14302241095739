<!-- Breadcrumb -->
<nav class="breadcrumb bg-transparent mb-4 p-0 small">
  <ul class="breadcrumb-list list-inline d-flex mb-0">
    <li class="breadcrumb-list-item list-inline-item mr-0"><a class="breadcrumb-list-link" [routerLink]="['/']">Home</a></li>
    <li class="breadcrumb-list-item list-inline-item mr-0"><a class="breadcrumb-list-link" [routerLink]="['/','events']">Eventos</a></li>
    <li class="breadcrumb-list-item list-inline-item mr-0">{{event.nombre}}</li>
  </ul>
</nav>

<!-- Events Container -->
<div class="container">
  <!-- Header Events -->
  <div class="row">
    <div class="col-12 mb-3">
      <h1 class="title-section text-center">
        {{event.nombre}}
      </h1>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-6 mb-3 mb-lg-5">
      <img [src]="event.imagenPrincipal" class="img-fluid" alt="Img del evento">
    </div>
    <div class="col-12 col-lg-6 mb-5">
      <div [innerHtml]="event.descripcion"></div>
      <ul class="list-group mb-3">
        <li
          class="list-group-item"
          *ngIf="event?.nombreCategoria">
          <i class="icon-tag"></i> {{event.nombreCategoria}}
        </li>
        <li
          class="list-group-item"
          *ngIf="event?.fecha">
          <i class="icon-calendar"></i> {{event.fecha}}
        </li>
        <li
          class="list-group-item"
          *ngIf="event?.lugar">
          <i class="icon-location-pin"></i> {{event.lugar}}
        </li>
        <li
          class="list-group-item"
          *ngIf="event?.responsable?.nombre">
          <i class="icon-user"></i> {{event.responsable.nombre}}
        </li>
        <li
          class="list-group-item"
          *ngIf="event?.responsable?.email">
          <i class="icon-envelope"></i> {{event.responsable.email}}
        </li>
        <li
          class="list-group-item"
          *ngIf="event?.responsable?.movil">
          <i class="icon-call-in"></i> {{event.responsable.movil}}
        </li>
      </ul>
      <a [href]="event.anexos" target="_blank" *ngIf="event.anexos" class="btn btn-outline-primary">Ver
        Anexos</a>
      <h5 class="mt-3 w700">Más Información</h5>
      <!-- More Information -->
      <div id="information" class="accordion more-information my-3" *ngIf="event?.contenidos">
        <div class="card" *ngFor="let contenido of event.contenidos; index as i">
          <div class="card-header icon"
               [ngClass]="{'collapsed':!isCollapsedAccordion}"
               data-toggle="collapse"
               data-target="#collapseAccordion"
               (click)="isCollapsedAccordion = !isCollapsedAccordion"
               [attr.aria-expanded]="isCollapsedAccordion" aria-controls="collapseAccordion"
               [attr.data-target]="'#contenido-'+i">
            <h6 class="mb-0 w700">
              <a class="card-title">
                {{contenido.titulo}}
              </a>
            </h6>
          </div>
          <div [id]="'contenido-'+i" class="card-body small"
               [ngbCollapse]="!isCollapsedAccordion" [ngClass]="{'collapse':!isCollapsedAccordion}"
               data-parent="#information">
            <div [innerHtml]="contenido.contenido"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ADS -->
<app-advertisements *ngIf="cmsData" [typeContent]="'espacios'" [positions]="2"
                    [contentData]="cmsData"></app-advertisements>
