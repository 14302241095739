<ng-container *ngIf="!switchSpinnerService.state">

  <!-- Breadcrumb -->
  <nav class="breadcrumb bg-transparent mb-4 p-0 small">
    <ul class="breadcrumb-list list-inline d-flex mb-0">
      <li class="breadcrumb-list-item list-inline-item mr-0"><a class="breadcrumb-list-link" [routerLink]="['/']">Home</a></li>
      <ng-template #breadcrumbCategories>
        <li class="breadcrumb-list-item list-inline-item mr-0"
            *ngFor="let breadcrumbItem of category.breadcrumb.slice(0, -1)">
          <a class="breadcrumb-list-link" [routerLink]="['/', breadcrumbItem?.url.split('/')[2]]"
             [title]="breadcrumbItem.title">{{breadcrumbItem.name}}</a>
        </li>
        <li class="breadcrumb-list-item list-inline-item mr-0">
          {{ category.title || currentFilters.searchText || currentFilters.slug }}
          {{ currentFilters.slugPromition ? 'Ofertas' : '' }}
          {{ currentFilters.typeProducts ? currentFilters.typeProducts : '' | uppercaseFirstLetter}}
        </li>
      </ng-template>
      <ng-container *ngIf="!(category); else breadcrumbCategories">
        <li class="breadcrumb-list-item list-inline-item mr-0" aria-current="page">
          Búsqueda: <span class="text-uppercase w700">{{currentFilters.searchText}}</span>
        </li>
      </ng-container>
    </ul>
  </nav>
  <div class="container">
    <div class="row">
      <!-- Banner Category -->
      <div class="col-12 mb-3 d-none d-lg-block" *ngIf="category?.bannerImgComplete">
        <img [src]="category?.bannerImgComplete" [alt]="category?.title" alt="Placeholder" class="img-fluid">
      </div>
      <!-- Ad -->
      <app-advertisements class="d-none d-lg-block" *ngIf="cms" [typeContent]="'espacios'" [positions]="1"
                          [contentData]="cms"></app-advertisements>
      <!-- Aside -->
      <aside class="col-12 col-lg-3">
        <!-- Filter: Price Desktop -->
        <section class="accordion d-none d-lg-block"
                 *ngIf="currentFilters.filters.productLowPrice != null|| currentFilters.filters.productHighPrice != null">
          <div class="card">
            <div class="card-header filters"
                 [ngClass]="{'collapsed':isCollapsedPrice}"
                 id="headingPrice"
                 data-toggle="collapse"
                 data-target="#collapsePrice"
                 aria-controls="collapsePrice"
                 (click)="isCollapsedPrice = !isCollapsedPrice"
                 [attr.aria-expanded]="isCollapsedPrice">
              <h6 class="text-uppercase mb-0">Rango de precio</h6>
            </div>
            <div id="collapsePrice" class="card-body p-4"
                 [ngClass]="{'collapse':isCollapsedPrice}"
                 [ngbCollapse]="!isCollapsedPrice"
                 data-parent="#accordion">
              <div class="filter-price d-flex">
                <div class="filter-price-item">
                  <span class="text-secondary text-uppercase">Mínimo</span>
                  <input class="filter-price-input form-control small"
                         type="number"
                         placeholder="min"
                         min="0"
                         placeholder="0"
                         [(ngModel)]="filterProductLowPrice"
                         [max]="currentFilters.filters.productHighPrice"
                         (keydown.enter)="updateListByPrice()">
                </div>
                <div class="filter-price-item">
                  <span class="text-secondary text-uppercase">Máximo</span>
                  <input class="filter-price-input form-control small"
                         type="number"
                         placeholder="0"
                         min="0"
                         [(ngModel)]="filterProductHighPrice"
                         [min]="filterProductLowPrice"
                         [max]="currentFilters.filters.productHighPrice"
                         (keydown.enter)="updateListByPrice()">
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <button class="filter-price-button" (click)="updateListByPrice()">Filtrar</button>
              </div>
            </div>
          </div>
        </section>
        <!-- Filter: Attributes Desktop -->
        <section class="accordion d-none d-lg-block"
                 *ngIf="categoryAttributes && categoryAttributes?.data?.length">
          <div class="card">
            <div class="card-header filters"
                 id="headingAttributes"
                 data-toggle="collapse"
                 data-target="#collapseAttributes"
                 aria-controls="collapseAttributes"
                 [ngClass]="{'collapsed':isCollapsedAttributes}"
                 (click)="isCollapsedAttributes = !isCollapsedAttributes"
                 [attr.aria-expanded]="isCollapsedAttributes">
              <h6 class="text-uppercase mb-0">Seleccione marca</h6>
            </div>
            <div id="collapseAttributes" class="card-body p-4"
                 [ngClass]="{'collapse':isCollapsedAttributes}"
                 [ngbCollapse]="!isCollapsedAttributes"
                 aria-labelledby="headingAttributes"
                 data-parent="#accordion">
              <app-filter-by-attribute (filterChange)="updateListByAttributes($event)"
                                       [markedAttributes]="currentFilters.filters.attributes"
                                       [attributes]="categoryAttributes?.data">
              </app-filter-by-attribute>
            </div>
          </div>
        </section>
        <!-- Ad Aside -->
        <aside>
          <app-advertisements *ngIf="cms" class="d-none d-lg-block" typeContent="espacios" positions="2"
                              [contentData]="cms"></app-advertisements>
        </aside>
      </aside>
      <!-- Content Catalogue -->
      <div class="col-12 col-lg-9">
        <!-- Pare y Compare Message -->
        <div class="alert d-flex align-items-center alert-dark alert-dismissible fade show" role="alert"
             *ngIf="parametersService?.page?.mostrar_seccion_pare_compare && parametersService?.page?.seccion_pare_compare_texto">
          <i class="icon-info h2 mb-0 mr-3 text-danger"></i>
          <p class="small text-justify mb-0" [innerHTML]="parametersService?.page?.seccion_pare_compare_texto"></p>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!-- Info Search -->
        <div class="row mb-4" id="category-info" #categoryInfo>
          <ng-template #notCategory>
            <div class="col-12 d-flex flex-column justify-content-center">
              <div class="d-flex align-items-center">
                <span class="text-secondary">
                  Búsqueda: <span
                  class="text-uppercase w700">{{ currentFilters.searchText || currentFilters.slug }}</span>
                </span>
              </div>
            </div>
          </ng-template>
        </div>
        <!-- Toolbar -->
        <div class="row mb-4">
          <div class="col-12">
            <div class="toolbar">
              <div class="row mb-3 mt-lg-0">
                <div class="col align-self-end d-flex">
                  <!-- Button Clean Filters -->
                  <button type="button" class="btn btn-primary btn-clear d-flex align-items-center" *ngIf="filtered" (click)="clearFilter()">
                    <svg class="bi bi-backspace-reverse" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                            d="M9.08 2H2a1 1 0 00-1 1v10a1 1 0 001 1h7.08a1 1 0 00.76-.35L14.682 8 9.839 2.35A1 1 0 009.08 2zM2 1a2 2 0 00-2 2v10a2 2 0 002 2h7.08a2 2 0 001.519-.698l4.843-5.651a1 1 0 000-1.302L10.6 1.7A2 2 0 009.08 1H2z"
                            clip-rule="evenodd"/>
                      <path fill-rule="evenodd"
                            d="M9.854 5.146a.5.5 0 010 .708l-5 5a.5.5 0 01-.708-.708l5-5a.5.5 0 01.708 0z"
                            clip-rule="evenodd"/>
                      <path fill-rule="evenodd"
                            d="M4.146 5.146a.5.5 0 000 .708l5 5a.5.5 0 00.708-.708l-5-5a.5.5 0 00-.708 0z"
                            clip-rule="evenodd"/>
                    </svg>
                    <span class="d-none d-lg-inline-block align-middle ml-1">Limpiar búsqueda</span>
                  </button>
                  <!-- Button Filters Mobile -->
                  <a type="button" class="d-flex d-lg-none btn btn-default" title="Filtros"
                     [ngClass]="{'collapsed':!isCollapsedFilters}" data-toggle="collapse"
                     data-target="#collapseFilters"
                     (click)="isCollapsedFilters = !isCollapsedFilters"
                     [attr.aria-expanded]="isCollapsedFilters" aria-controls="collapseFilters">
                    <i class="icon icon-equalizer"></i>
                  </a>
                </div>
              </div>

              <div class="row">
                <!-- Search -->
                <div class="col-12 col-lg-7 mb-2 px-1 mb-lg-0">

                  <div class="input-group mb-3">
                    <span class="input-group-btn">
                      <button id="internal-search-addon"
                              class="btn btn-primary toolbar-search-button mr-0"
                              type="button"
                              (click)="updateListByText(filterMobileText.value)">
                        <i-feather name="search" class="toolbar-search-icon"></i-feather>
                      </button>
                    </span>
                    <input class="form-control toolbar-search-input small"
                           placeholder="Búsqueda detallada"
                           #filterText type="text"
                           aria-label="Buscar"
                           aria-describedby="internal-search-addon"
                           (keydown.enter)="updateListByText(filterText.value)"
                           (blur)="updateListByText(filterText.value)">
                  </div>

                </div>
                <!--                <div class="col-6 col-lg p-lg-0">-->
                <!--                  <hr>-->
                <!--                </div>-->
                <!-- Order -->
                <div class="col-6 col-lg-3 px-0">
                  <select class="form-control text-uppercase small px-1" (change)="updateListBySort($event.target.value)">
                    <option value="">Ordenar</option>
                    <option value="1">Posición Inicial</option>
                    <option value="2">Productos de A-Z</option>
                    <option value="3">Productos de Z-A</option>
                    <option value="4">Precio Menor a Mayor</option>
                    <option value="5">Precio Mayor a Menor</option>
                  </select>
                </div>
                <!-- View -->
                <div class="col-6 col-lg-2 px-1">
                  <select class="form-control text-uppercase small"
                          (change)="updateListByPageSize($event.target.value)">
                    <option value="">Ver</option>
                    <option value="12">12</option>
                    <option value="24">24</option>
                  </select>
                </div>
              </div>

              <!-- More Buttons -->
<!--              <div class="row mt-2 mt-lg-0">-->
<!--                <div class="col align-self-end d-flex justify-content-center">-->
<!--                  &lt;!&ndash; Button Clean Filters &ndash;&gt;-->
<!--                  <button type="button" class="btn btn-primary btn-clear d-flex align-items-center rounded mr-3" *ngIf="filtered" (click)="clearFilter()">-->
<!--                    <svg class="bi bi-backspace-reverse" width="1em" height="1em" viewBox="0 0 16 16"-->
<!--                         fill="currentColor" xmlns="http://www.w3.org/2000/svg">-->
<!--                      <path fill-rule="evenodd"-->
<!--                            d="M9.08 2H2a1 1 0 00-1 1v10a1 1 0 001 1h7.08a1 1 0 00.76-.35L14.682 8 9.839 2.35A1 1 0 009.08 2zM2 1a2 2 0 00-2 2v10a2 2 0 002 2h7.08a2 2 0 001.519-.698l4.843-5.651a1 1 0 000-1.302L10.6 1.7A2 2 0 009.08 1H2z"-->
<!--                            clip-rule="evenodd"/>-->
<!--                      <path fill-rule="evenodd"-->
<!--                            d="M9.854 5.146a.5.5 0 010 .708l-5 5a.5.5 0 01-.708-.708l5-5a.5.5 0 01.708 0z"-->
<!--                            clip-rule="evenodd"/>-->
<!--                      <path fill-rule="evenodd"-->
<!--                            d="M4.146 5.146a.5.5 0 000 .708l5 5a.5.5 0 00.708-.708l-5-5a.5.5 0 00-.708 0z"-->
<!--                            clip-rule="evenodd"/>-->
<!--                    </svg>-->
<!--                    <span class="align-middle ml-2">Limpiar filtros</span>-->
<!--                  </button>-->
<!--                  &lt;!&ndash; Button Filters Mobile &ndash;&gt;-->
<!--                  <a type="button" class="d-flex d-lg-none btn btn-primary align-items-center rounded" title="Filtros"-->
<!--                     [ngClass]="{'collapsed':!isCollapsedFilters}" data-toggle="collapse"-->
<!--                     data-target="#collapseFilters"-->
<!--                     (click)="isCollapsedFilters = !isCollapsedFilters"-->
<!--                     [attr.aria-expanded]="isCollapsedFilters" aria-controls="collapseFilters">-->
<!--                    <i class="icon icon-equalizer"></i> <span class="ml-2">Filtrar</span>-->
<!--                  </a>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
        <!-- Filters Mobile -->
        <div class="filter-options d-block d-lg-none">
          <div class="collapse-filters bg-white card-body w-100"
               id="collapseFilters" [ngbCollapse]="!isCollapsedFilters"
               [ngClass]="{'collapse':!isCollapsedFilters}">
            <div class="d-flex justify-content-between">
              <h2 class="w700">
                Filtros
              </h2>
              <!-- btn Close -->
              <button type="button" class="btn btn-default btn-close"
                      [ngClass]="{'collapsed':!isCollapsedFilters}"
                      data-toggle="collapse"
                      data-target="#collapseFilters"
                      (click)="isCollapsedFilters = !isCollapsedFilters"
                      [attr.aria-expanded]="isCollapsedFilters" aria-controls="collapseFilters"
                      title="Filtros">
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="w-100">
              <hr class="m-0">
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item px-0" *ngIf="filtered">
                <button type="button" class="btn btn-sm btn-outline-danger"
                        (click)="clearFilter()">
                  <svg class="bi bi-backspace-reverse" width="1em" height="1em" viewBox="0 0 16 16"
                       fill="currentColor"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          d="M9.08 2H2a1 1 0 00-1 1v10a1 1 0 001 1h7.08a1 1 0 00.76-.35L14.682 8 9.839 2.35A1 1 0 009.08 2zM2 1a2 2 0 00-2 2v10a2 2 0 002 2h7.08a2 2 0 001.519-.698l4.843-5.651a1 1 0 000-1.302L10.6 1.7A2 2 0 009.08 1H2z"
                          clip-rule="evenodd"/>
                    <path fill-rule="evenodd"
                          d="M9.854 5.146a.5.5 0 010 .708l-5 5a.5.5 0 01-.708-.708l5-5a.5.5 0 01.708 0z"
                          clip-rule="evenodd"/>
                    <path fill-rule="evenodd"
                          d="M4.146 5.146a.5.5 0 000 .708l5 5a.5.5 0 00.708-.708l-5-5a.5.5 0 00-.708 0z"
                          clip-rule="evenodd"/>
                  </svg>
                  Limpiar búsqueda
                </button>
              </li>
              <li class="list-group-item px-0">
                <h6>Rango de precio</h6>
                <div class="align-items-center">
                  <div class="form-row">
                    <!-- Filter: Prices -->
                    <div class="form-group col-12">
                      <input type="number" class="form-control" placeholder="min" min="0"
                             placeholder="Mínimo"
                             [(ngModel)]="filterProductLowPrice"
                             [max]="currentFilters.filters.productHighPrice"
                             (keydown.enter)="updateListByPrice()"
                             appOnlyNumbers>
                    </div>
                    <div class="form-group col-12">
                      <input type="number" placeholder="max" class="form-control"
                             placeholder="Máximo"
                             [(ngModel)]="filterProductHighPrice"
                             [min]="filterProductLowPrice"
                             [max]="currentFilters.filters.productHighPrice"
                             (keydown.enter)="updateListByPrice()"
                             appOnlyNumbers>
                    </div>
                    <div class="col-12">
                      <button class="btn btn-block btn-primary"
                              (click)="updateListByPrice()">
                        <i class="fas fa-funnel-dollar"></i> Filtrar
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <!-- Filter: Attributes -->
            <h6 class="mt-2">Seleccione marca</h6>
            <app-filter-by-attribute (filterChange)="updateListByAttributes($event)"
                                     [markedAttributes]="currentFilters.filters.attributes"
                                     [attributes]="categoryAttributes?.data">
            </app-filter-by-attribute>
          </div>
        </div>
        <!-- product list -->
        <section class="box-product mb-3">
          <ng-container *ngIf="localSpinner; then localSpinnerTemplate; else productListTemplate"></ng-container>
          <ng-template #productListTemplate>
            <div class="row" *ngIf="products.length; else notProducts">
              <div class="col-6 col-lg-4 mb-3" *ngFor="let product of products">
                <!-- Component: Box Products -->
                <app-box-product [product]="product" [showRating]="true"></app-box-product>
              </div>
            </div>
          </ng-template>
          <!-- local spinner -->
          <ng-template #localSpinnerTemplate>
            <div class="row d-flex justify-content-center align-items-center" style="height: 300px;">
              <div class="d-flex flex-column text-center">
                <div class="spinner-grow text-primary" style="width: 6rem; height: 6rem;" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <p class="mt-1 w700"> Cargando...</p>
              </div>
            </div>
          </ng-template>
          <ng-template #notProducts>
            <div class="alert alert-info" role="alert">No se han encontrado productos para esta búsqueda</div>
          </ng-template>
        </section>
        <!-- Pagination -->
        <div class="row d-flex align-items-center" *ngIf="!localSpinner">
          <div class="col-auto small text-uppercase">
            Mostrando {{ pagination.page }} a {{ pagination.pageSize }} de {{ pagination.total }} productos
          </div>
          <div class="col">
            <hr>
          </div>
          <div class="col-12 col-lg-auto d-flex justify-content-center">
            <ngb-pagination [(page)]="pagination.page" [pageSize]="pagination.pageSize" [maxSize]="3"
                            [collectionSize]="pagination.total"
                            (pageChange)="handlePageChange($event)">
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
