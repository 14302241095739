<div class="container-fluid offline vh-100">
  <div class="row vh-100">
    <div class="col-12 vh-100 d-flex flex-column justify-content-center align-items-center">
      <img class="img-fluid mb-3" src="../assets/images/off-line.png">
      <h1 class="text-center w700">¡Hola!, estamos actualizando nuestras ofertas...</h1>
      <h4 class="text-center w700">En breve estaremos de nuevo contigo.</h4>
      <p class="text-center mb-0">Nuestras actualizaciones periodicas actualizan precios, productos y promociones para que disfrutes de lo mejor de nuestra tienda.</p>
    </div>
  </div>
<!--<div class="page">-->
<!--  <div id="fh5co-container" class="js-fullheight">-->
<!--    <div class="countdown-wrap js-fullheight d-flex justify-content-center align-items-center">-->
<!--      <div class="row">-->
<!--        <div class="col-md-12 text-center margen-offline">-->
<!--          <div class="display-t js-fullheight">-->
<!--            <div class="display-tc animate-box">-->
<!--              <nav class="fh5co-nav" role="navigation">-->
<!--                <div *ngIf="cmsData" id="fh5co-logo">-->
<!--                  <app-advertisements *ngIf="cmsData.espacios[1]" [typeContent]="'espacios'" [positions]="1"-->
<!--                                      [contentData]="cmsData"></app-advertisements>-->
<!--                </div>-->
<!--              </nav>-->
<!--              <h1>¡Vuelve Pronto!</h1>-->
<!--              <h2>Nuestro sitio web está siendo remodelado.</h2>-->
<!--              <div class="countdown">-->
<!--                <div id="clock"-->
<!--                     class="simply-countdown d-flex justify-content-center align-items-center flex-wrap"></div>-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div class="col-md-12 desc">-->
<!--                  <h2>Pronto encontrarás una nueva tienda <br/>-->
<!--                    para que sigas disfruntado de nuestros productos<br/>-->
<!--                    y servicios.-->
<!--                  </h2>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="bg-cover js-fullheight">-->
<!--      <div *ngIf="cmsData">-->
<!--        <app-advertisements *ngIf="cmsData.espacios[2]" [typeContent]="'espacios'" [positions]="2"-->
<!--                            [contentData]="cmsData"></app-advertisements>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
