<!-- Product List -->
<div class="container-fluid">
  <div class="row">
    <div class="col-12 text-center">
      <span class="subtitle-section-home">Productos</span>
      <h2 class="title-section title-section-home h1 mb-5">{{productInformation?.title}}</h2>
    </div>
  </div>
  <!-- Loading -->
  <div class="row" *ngIf="loadingRelatedProducts">
    <div class="col-12 text-center">
      <img ng-src="./assets/img/loader.gif"/>
      <small>Cargando productos...</small>
    </div>
  </div>
  <!-- Box Products -->
  <section class="box-product mb-2">
    <div class="row">
      <div class="col-6 col-lg-2 mb-3" *ngFor="let item of items">
        <!-- Component: Box Products -->
        <app-box-product [product]="item"></app-box-product>
      </div>
    </div>
  </section>
</div>
