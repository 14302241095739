<ng-container *ngIf="parametersService.page?.pageOnline">

  <!-- Modal Location -->
  <app-modal-location *ngIf="parametersService.page?.showLocationQuestion || (parametersService.page?.deliveryParams?.delivery_mode || parametersService.page?.deliveryParams?.store_pickup_mode)"></app-modal-location>

  <!-- Custom Modal -->
  <app-custom-modal></app-custom-modal>

  <!-- Anuncio -->
  <app-advertisements *ngIf="cmsData" [typeContent]="'espacios'" [positions]="1" [contentData]="cmsData"></app-advertisements>

  <!-- Top bar -->
  <div class="topbar d-none">
    <div class="container-fluid h-100">
      <div class="row d-flex justify-content-between h-100">
        <!-- Social Networks -->
        <div class="col d-flex align-items-center">
          <ul class="social-networks list-inline d-flex m-0">
            <li *ngIf="parametersService.company?.info?.social?.facebook?.show" class="social-networks-item list-inline-item">
              <a class="social-networks-link" [href]="parametersService.company?.info?.social?.facebook?.link" target="_blank">
                <i class="fab fa-facebook-f m-0"></i>
              </a>
            </li>
            <li *ngIf="parametersService.company?.info?.social?.instagram?.show" class="social-networks-item list-inline-item">
              <a class="social-networks-link" [href]="parametersService.company?.info?.social?.instagram?.link" target="_blank">
                <i class="fab fa-instagram m-0"></i>
              </a>
            </li>
            <li *ngIf="parametersService.company?.info?.social?.youtube?.show" class="social-networks-item list-inline-item">
              <a class="social-networks-link" [href]="parametersService.company?.info?.social?.youtube?.link" target="_blank">
                <i class="fab fa-youtube m-0"></i>
              </a>
            </li>
            <li *ngIf="parametersService.company?.info?.social?.twitter?.show" class="social-networks-item list-inline-item">
              <a class="social-networks-link" [href]="parametersService.company?.info?.social?.twitter?.link" target="_blank">
                <i class="fab fa-twitter m-0"></i>
              </a>
            </li>
            <li *ngIf="parametersService.company?.info?.social?.pinterest?.show" class="social-networks-item list-inline-item">
              <a class="social-networks-link" [href]="parametersService.company?.info?.social?.pinterest?.link" target="_blank">
                <i class="fab fa-pinterest m-0"></i>
              </a>
            </li>
            <li *ngIf="parametersService.company?.info?.social?.linkedin?.show" class="social-networks-item list-inline-item">
              <a class="social-networks-link" [href]="parametersService.company?.info?.social?.linkedin?.link" target="_blank">
                <i class="fab fa-linkedin-in m-0"></i>
              </a>
            </li>
          </ul>
        </div>
        <!-- Phone -->
        <div class="col d-flex justify-content-end align-items-center">
          <div class="topbar-phone">
            <i-feather name="phone-call" class="topbar-phone-icon"></i-feather>
            <span>{{parametersService.company?.info?.company?.pbx}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Desktop Menu -->
  <header class="d-none d-lg-block">
    <div class="header container-fluid pt-4 pb-5">
      <div class="row">
        <!-- Logo -->
        <div class="col">
          <a [routerLink]="['/']">
            <img class="logo-header"
              src="{{parametersService.company?.app?.header?.logo?.logo_front}}"
              alt="{{parametersService.company?.app?.header?.logo?.title}}">
          </a>
          <ng-template #title>
            <h2 class="text-hidden" [innerHtml]="parametersService.company?.app?.header?.logo?.title"></h2>
          </ng-template>
        </div>
        <!-- Search -->
        <div class="col-auto">
          <app-search [placeholder]=" parametersService.company?.app?.header?.placeholder"
            *ngIf=" parametersService.company?.config?.menu?.showSearchInsideMenu">
          </app-search>
        </div>
        <!-- Phone & Icons -->
        <div class="align-items-end col d-flex flex-column">          
          <!-- Phone -->
          <div class="topbar-phone">
            <i-feather name="phone-call" class="topbar-phone-icon"></i-feather>
            <span>{{parametersService.company?.info?.company?.pbx}}</span>
          </div>
          <!-- Icons -->
          <ul class="icons-header list-inline d-flex m-0">
            <!-- Account -->
            <li class="icons-header-item icons-header-user list-inline-item" *ngIf="authService.isAuthenticated">
              <a class="icons-header-link">
                <i-feather name="user" class="icons-header-icon"></i-feather>
                {{ authService.currentUserValue.firstname }}
              </a>
              <div class="dropdown-menu dropdown-menu-right w-100 animate slideIn">
                <a class="dropdown-item" [routerLink]="['/','account']">
                  <i-feather name="user" class="mr-1"></i-feather> <small>Cuenta</small>
                </a>
                <a class="dropdown-item" [routerLink]="['/','orders']">
                  <i-feather name="file-text" class="mr-1"></i-feather> <small>Pedidos</small>
                </a>
                <a class="dropdown-item" [routerLink]="['/','address']">
                  <i-feather name="map-pin" class="mr-1"></i-feather> <small>Direcciones</small>
                </a>
                <a class="dropdown-item" [routerLink]="['/']" (click)="logout()">
                  <i-feather name="log-out" class="mr-1"></i-feather> <small>Cerrar Sesión</small>
                </a>
              </div>
            </li>
            <!-- Login -->
            <li class="icons-header-item icons-header-user list-inline-item" *ngIf="!authService.isAuthenticated">
              <a class="icons-header-link"><i-feather name="user" class="icons-header-icon"></i-feather></a>
              <div class="dropdown-menu dropdown-menu-right animate slideIn">
                <a class="dropdown-item d-flex align-items-center" [routerLink]="['/','login']">
                  <i-feather name="user" class="mr-1"></i-feather> <small>Iniciar Sesión</small>
                </a>
                <a class="dropdown-item d-flex align-items-center" [routerLink]="['/','register']">
                  <i-feather name="user-plus" class="mr-1"></i-feather> <small>Registrarse</small>
                </a>
              </div>
            </li>
            <!-- Favorites -->
            <li class="icons-header-item list-inline-item" *ngIf="authService.isAuthenticated">
              <a class="icons-header-link" [routerLink]="[ '/favorites' ]"><i-feather name="heart" class="icons-header-icon"></i-feather></a>
            </li>
            <!-- Location -->
            <li class="icons-header-item icons-header-location list-inline-item"
              *ngIf="parametersService.page?.showLocationQuestion ||
              (parametersService.page?.deliveryParams?.delivery_mode ||
              parametersService.page?.deliveryParams?.store_pickup_mode)">
              <a class="icons-header-link" (click)="locationService.showLocationModal()">
                <i-feather name="map-pin" class="icons-header-icon"></i-feather>
              </a>
              <div class="dropdown-menu dropdown-location dropdown-menu-right animate slideIn">
                <div class="row p-3">
                  <div class="col-12">
                    <p class="text-justify m-0 small">En este momento <span [innerText]="textLocation()"></span> es el destino de tu pedido. Si deseas puedes ajustarlo presionando la opción <span class="w700">Cambiar</span></p>
                  </div>
                </div>
                <div class="row no-gutters px-3 pb-3">
                  <div class="col">
                    <button type="button" class="btn btn-block"
                      [ngClass]="{'btn-primary': activeInfoModalLocation == true, 'btn-outline-primary':activeInfoModalLocation == false}"
                      (mouseenter)="changeActiveInfoModalLocation(2)"
                      (click)="locationService.showLocationModal()">
                      Cambiar
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <!-- Shopping Cart -->
            <li class="icons-header-item list-inline-item">
              <a class="icons-header-link" [routerLink]="[ '/order' ]">
                <i class="lni lni-shopping-basket icons-header-icon mt-2"></i>
                <span *ngIf="!(totalItemsInCart$ | async) as total">0</span>
                <span *ngIf="(totalItemsInCart$ | async) as total">{{total}}</span>
                Items
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Menu -->
    <div class="container-fluid">
      <nav class="navbar d-flex justify-content-center">
        <ul class="list-inline d-flex align-center m-0">
          <li class="navbar-item list-inline-item">
            <a class="navbar-item-link" [routerLink]="['/']">Home</a>
          </li>
          <!-- Mega Menu -->
          <app-mega-menu class="d-flex align-center"
            [hidden]="!parametersService.company?.config?.header?.showCategories"
            [promocion]="parametersService.company?.config?.header?.showOfferts"
            *ngFor="let menu of categoriesMenu"
            [menu]="menu">
          </app-mega-menu>
          <li class="navbar-item list-inline-item" *ngIf="parametersService.company?.config?.header?.showOfferts">
            <a class="navbar-item-link" [routerLink]="['/', 'promocion', 'products']">Ofertas</a>
          </li>
          <li class="navbar-item list-inline-item">
            <a class="navbar-item-link" [routerLink]="['/', 'politics' ,'quienes-somos']">Nosotros</a>
          </li>
          <li class="navbar-item list-inline-item">
            <a class="navbar-item-link" [routerLink]="['/','headquarters']">Sedes</a>
          </li>
          <li class="navbar-item list-inline-item" *ngIf="parametersService.company?.config?.header?.showBlog">
            <a class="navbar-item-link" [routerLink]="['blog']">Blog</a>
          </li>
          <li class="navbar-item list-inline-item" *ngIf="parametersService.company?.config?.header?.showEvent">
            <a class="navbar-item-link" [routerLink]="['events']">Eventos</a>
          </li>
          <li class="navbar-item list-inline-item" *ngIf="parametersService.company?.config?.header?.showContact">
            <a class="navbar-item-link" [routerLink]="['/','contact']">Contáctenos</a>
          </li>
        </ul>
      </nav>
    </div>
  </header>

  <!-- Mobile Menu -->
  <header class="header-mobile d-block d-lg-none">
    <div class="container-fluid py-2">
      <div class="row d-flex align-items-center">
        <!-- Menú -->
        <div class="col-4 list-items-mobile">
          <a class="list-items-mobile-link" (click)="toggleMenu = true">
            <i class="list-items-mobile-icon fas fa-bars"></i>
          </a>
        </div>
        <!-- Logo -->
        <div class="col-4 d-flex justify-content-center align-items-center">
          <a [routerLink]="['/']">
            <img class="img-fluid" src="{{parametersService.company?.app?.header?.logo?.logo_front}}"
                 alt="{{parametersService.company?.app?.header?.logo?.title}}"/>
          </a>
          <ng-template #title>
            <h1 class="text-hidden" [innerHtml]="parametersService.company?.app?.header?.logo?.title"></h1>
          </ng-template>
        </div>
        <!-- Actions -->
        <div class="col-4 list-items-mobile d-flex justify-content-end">
          <!-- Ubicación -->
          <a class="list-items-mobile-link pl-2" (click)="locationService.showLocationModal()">
            <i class="list-items-mobile-icon icon-location-pin"></i>
          </a>
          <!-- Component: Search -->
          <a class="list-items-mobile-link pl-2" (click)="doFocusInSearchInput()" onclick="openSearch()">
            <i class="list-items-mobile-icon icon-magnifier"></i>
          </a>
          <!-- Shopping Cart -->
          <a class="list-items-mobile-link pl-2" [routerLink]="[ '/order' ]">
            <i class="list-items-mobile-icon icon-bag"></i>
            <span class="badge badge-primary badge-cart rounded-circle border-white"
                  *ngIf="(totalItemsInCart$ | async) as total">{{total}}</span>
          </a>
        </div>
      </div>
    </div>
    <div class="position-relative">
      <app-search id="mobileSearch" class="mobile-search"
                  [placeholder]="parametersService.company?.app?.header?.placeholder"
                  *ngIf="parametersService.company?.config?.menu?.showSearchInsideMenu">
      </app-search>
    </div>
  </header>

  <!-- Aside Mobile Content -->
  <aside id="sideNav" class="sidenav" [ngStyle]="{ 'width': toggleMenu ? '100%' : '0%' }">
    <!-- Header -->
    <div class="container-fluid">
      <div class="row sidenav-header py-2">
        <div class="col-4 offset-4 d-flex justify-content-center align-items-center">
          <a [routerLink]="['/']" (click)="toggleMenu = false">
            <img class="img-fluid" [src]="parametersService.company?.app?.header?.logo?.logo_front"
                 [alt]="parametersService.company?.app?.header?.logo?.title"/>
          </a>
        </div>
        <div class="col-4 d-flex justify-content-end align-items-center">
          <a class="btn-close" (click)="toggleMenu = false">
            <i class="fas fa-times"></i>
          </a>
        </div>
      </div>
    </div>
    <!-- Content -->
    <div class="row">
      <div class="col-12">
        <!-- My Account -->
        <div *ngIf="authService.isAuthenticated">
          <p class="title-menu mb-0 w700">¡Hola! {{ authService.currentUserValue.fullname }}</p>
          <div class="accordion">
            <div class="card m-0 border-bottom">
              <div class="card-header" id="collapseMyAccount" [ngClass]="{'collapsed':!isCollapsedMyAccount}">
                <span>
                  <i class="icon-user"></i> Mi Perfil
                </span>
                <a (click)="isCollapsedMyAccount = !isCollapsedMyAccount" [attr.aria-expanded]="isCollapsedAddAddress"
                  aria-controls="collapseMyAccount">
                  <i class="fas fa-chevron-down"></i>
                  <i class="fas fa-chevron-up"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="collapse multi-collapse" [ngbCollapse]="!isCollapsedMyAccount"
                   data-parent="#collapseMyAccount">
                <div class="p-0 rounded-0 border-0">
                  <a class="btn-menu-accordion-item d-block text-left" [routerLink]="['/','account']"
                     (click)="toggleMenu = false">
                    <i class="icon-user"></i> Cuenta
                  </a>
                  <a class="btn-menu-accordion-item d-block text-left" [routerLink]="['/','orders']"
                     (click)="toggleMenu = false">
                    <i class="icon-docs"></i> Pedidos
                  </a>
                  <a class="btn-menu-accordion-item d-block text-left" [routerLink]="['/','favorites']"
                     (click)="toggleMenu = false">
                    <i class="icon-heart"></i> Favoritos
                  </a>
                  <a class="btn-menu-accordion-item d-block text-left" [routerLink]="['/','address']"
                     (click)="toggleMenu = false"
                     *ngIf="!parametersService.page?.deliveryParams?.delivery_mode">
                    <i class="icon-location-pin"></i> Direcciones
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Register / Login -->
        <div *ngIf="!authService.isAuthenticated">
          <p class="title-menu mb-0 w700">
            ¡Bienvenidos!
          </p>
          <a class="btn-menu d-block text-left" [routerLink]="['/','login']" (click)="toggleMenu = false">
            <i class="icon-user"></i> Iniciar Sesión
          </a>
          <a class="btn-menu d-block text-left" [routerLink]="['/','register']" (click)="toggleMenu = false">
            <i class="icon-user-follow"></i> Registrarse
          </a>
        </div>
        <!-- Mega Menu -->
        <p class="title-menu mb-0 w700" [hidden]="!parametersService.company?.config?.header?.showCategories">Menú de
          Productos</p>
        <app-mega-menu *ngFor="let menu of categoriesMenu"
                       [hidden]="!parametersService.company?.config?.header?.showCategories" [menu]="menu"
                       (closeMenu)="toggleMenu = false">
        </app-mega-menu>
        <a class="btn-menu offert d-block text-left" *ngIf="parametersService.company?.config?.header?.showOfferts"
           [routerLink]="['/', 'promocion', 'products']" (click)="toggleMenu = false">
          % Ofertas
        </a>
        <!-- Others Options -->
        <p class="title-menu mb-0 w700">Otras Opciones</p>
        <a class="btn-menu d-block text-left" *ngIf="authService.isAuthenticated" [routerLink]="['/','favorites']"
           (click)="toggleMenu = false">
          <i class="icon-heart"></i> Favoritos
        </a>
        <a class="btn-menu d-block text-left" *ngIf="parametersService.company?.config?.header?.showBlog"
           [routerLink]="['blog']" (click)="toggleMenu = false">
          <i class="icon-book-open"></i> Blog
        </a>
        <a class="btn-menu d-block text-left" *ngIf="parametersService.company?.config?.header?.showEvent"
           [routerLink]="['events']" (click)="toggleMenu = false">
          <i class="icon-calendar"></i> Eventos
        </a>
        <a class="btn-menu d-block text-left" *ngIf="parametersService.company?.config?.header?.showKnowUs"
           [routerLink]="['/pages','quienes-somos']" (click)="toggleMenu = false">
          <i class="icon-briefcase"></i> Nosotros
        </a>
        <a class="btn-menu d-block text-left" *ngIf="parametersService.company?.config?.header?.showContact"
           [routerLink]="['/','contact']" (click)="toggleMenu = false">
          <i class="icon-envelope"></i> Contáctenos
        </a>
        <a class="btn-menu d-block text-left" *ngIf="authService.isAuthenticated" [routerLink]="['/']"
           (click)="logout();toggleMenu = false">
          <i class="icon-logout"></i> Cerrar Sesión
        </a>
      </div>
    </div>
  </aside>

  <ngx-json-ld [json]="schema"></ngx-json-ld>

</ng-container>