<ng-container *ngIf="!switchSpinnerService.state">

  <!-- Breadcrumb -->
  <nav class="breadcrumb bg-transparent mb-4 p-0 small">
    <ul class="breadcrumb-list list-inline d-flex mb-0">
      <li class="breadcrumb-list-item list-inline-item mr-0"><a class="breadcrumb-list-link" [routerLink]="['/']">Home</a></li>
      <li class="breadcrumb-list-item list-inline-item mr-0">Pedidos</li>
    </ul>
  </nav>

  <!-- Loader -->
  <div class="container" *ngIf="loadingUserOrders">
    <div class="row">
      <div class="col-12 py-5">
        <div class="small text-center">
          <i class="fas fa-spinner fa-pulse"></i> <span>Cargando información...</span>
        </div>
      </div>
    </div>
  </div>
  <!-- Order Empty -->
  <div class="container" *ngIf="loadedUserOrders && !userOrders.ordersQuotes.length">
    <!-- Header -->
    <div class="row">
      <div class="col-12">
        <h1 class="title-section text-center">
          Pedidos
        </h1>
      </div>
    </div>
    <!-- Content -->
    <div class="row">
      <div class="col-12 col-lg-6 mb-5">
        <!-- <h5 class="mb30">{{userOrders.ordersQuotes.length}} pedido(s)</h5>-->
        <p class="text-justify">Aún no ha realizado ningún pedido. Lo invitamos a realizar su primer pedido con la excelente calidad de nuestros productos</p>
        <p class="text-justify w700">¿Qué desea hacer ahora?</p>
        <!-- Actions Dekstop -->
        <div class="btn-group d-none d-lg-block">
          <button class="btn btn-outline-primary mr-1" [routerLink]="['/',slugCategoriaPrincipal]">
            <i class="icon icon-basket"></i> Comenzar a Comprar
          </button>
          <button class="btn btn-outline-secondary" [routerLink]="['/','contact']">
            <i class="icon-earphones-alt mr5"></i> Servicio al Cliente
          </button>
        </div>
        <!-- Actions Mobile -->
        <div class="btn-group d-block d-lg-none">
          <button class="btn btn-outline-primary btn-block mr-1" [routerLink]="['/',slugCategoriaPrincipal]">
            <i class="icon icon-basket"></i> Comenzar a Comprar
          </button>
          <button class="btn btn-outline-secondary btn-block" [routerLink]="['/','contact']">
            <i class="icon-earphones-alt mr5"></i> Servicio al Cliente
          </button>
        </div>
      </div>
      <!-- ADS -->
      <div class="col-12 col-lg-6 mb-5">
        <app-advertisements *ngIf="cmsData" [typeContent]="'espacios'" [positions]="1" [contentData]="cmsData">
        </app-advertisements>
      </div>
    </div>
  </div>
  <!-- Orders -->
  <div class="orders-container" *ngIf="loadedUserOrders && userOrders.ordersQuotes.length">
    <div class="container">
      <!-- Orders Management -->
      <div class="row">
        <div class="col-12">
          <h1 class="title-section text-center">
            Pedidos
          </h1>
        </div>
      </div>
      <!-- Filters -->
      <div class="row mb-3">
        <div class="col-12">
          <div id="accordion">
            <div class="card filter-options border-0 position-relative">
              <div class="bg-white px-0" id="headingThree">
                <div class="row no-gutters align-items-center">
                  <!-- Order -->
                  <div class="col-auto">
                    <div class="form-inline">
                      <select class="form-control text-uppercase small text-body" (change)="changeSort(+$event.target.value)">
                        <option value="2" data-column="orderId" data-order="desc">Nº Pedido Descendente</option>
                        <option value="1" data-column="orderId" data-order="asc">Nº Pedido Ascendente</option>
                        <option value="3" data-column="totalPrice" data-order="asc">Precio Menor a Mayor</option>
                        <option value="4" data-column="totalPrice" data-order="desc">Precio Mayor a Menor</option>
                      </select>
                    </div>
                  </div>
                  <!-- Quantity in View -->
                  <div class="col-auto ml-0 ml-lg-5">
                    <app-filter-rows-per-page [default]="requestOrders.limit" (changeEvent)="changeRowsPerPage($event)">
                    </app-filter-rows-per-page>
                  </div>
                  <!-- Search -->
                  <div class="col align-self-end d-flex justify-content-end">
                    <!-- Action -->
                    <button type="button" class="btn btn-default"
                            [ngClass]="{'collapsed':!isCollapsedFilters}"
                            data-toggle="collapse"
                            data-target="#collapseSearch"
                            (click)="isCollapsedSearch = !isCollapsedSearch"
                            [attr.aria-expanded]="isCollapsedSearch" aria-controls="collapseSearch">
                      <i class="icon icon-magnifier"></i>
                    </button>
                  </div>
                </div>
              </div>
              <!-- Search -->
              <div id="collapseSearch" class="collapse-search card-body"
                   [ngbCollapse]="!isCollapsedSearch" [ngClass]="{'collapse':!isCollapsedSearch}"
                   aria-labelledby="headingSearch" data-parent="#accordion">
                <!-- Search -->
                <div class="position-relative">
                  <input class="form-control input-search" type="text" name="dates" placeholder="Buscar"
                         [(ngModel)]="searchText">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Table Orders Desktop -->
      <div class="row d-none d-lg-flex mb-5">
        <div class="col-lg-12">
          <div class="table-responsive">
            <table class="table small">
              <thead>
              <tr>
                <th class="text-center">Pedido</th>
                <th class="text-center">Destino</th>
                <th class="text-center">Valor</th>
                <th class="text-center">Medio de Pago</th>
                <th class="text-center">Estado del Pago</th>
                <th class="text-center">Estado del pedido</th>
                <th class="text-center">Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let order of userOrdersFiltered | sortBy:[sortOrder]:[sortType] | filter:searchText"
                  current-page="currentPage">
                <td class="text-center">{{order.orderNumber}}</td>
                <td class="text-center">{{order.ciudadDestino}}</td>
                <td class="text-center">$ {{order.totalPrice | number : '1.0-0'}} COP</td>
                <td class="text-center">{{order.paymentName}}</td>
                <td class="text-center">{{order.paymentStateName}}</td>
                <td class="text-center">
                  {{order.orderState}}<br>
                  <div class="badge badge-success text-capitalize p-2" *ngIf="order.cancelState == 'activa'">
                    {{order.cancelState}}</div>
                  <div class="badge badge-danger text-capitalize p-2" *ngIf="order.cancelState == 'anulada'">
                    {{order.cancelState}}</div>
                </td>
                <td class="text-center">
                  <button class="btn btn-primary mr-1 btn-sm" [routerLink]="['/order/', order.orderId]"
                          ngbTooltip="Ver pedido No. {{order.orderNumber}}">
                    <i class="icon-magnifier-add"></i> Ver Pedido
                  </button>
                  <!--                <button class="btn btn-primary mr-1 btn-sm"-->
                  <!--                        *ngIf="parametersService.company?.config?.orders?.duplicateOrders"-->
                  <!--                        (click)="duplicateOrder(order)"-->
                  <!--                        ngbTooltip="Volver a Comprar">-->
                  <!--                  <i class="icon-docs"></i>-->
                  <!--                </button>-->
                  <!--                <button class="btn btn-primary mr-1 btn-sm"-->
                  <!--                        *ngIf="parametersService.company?.config?.orders?.paySavedOrders  && order.showPayOrderAction && order.cancelState == 'activa' && (order.paymentStateName !== 'Pagada' && order.paymentStateName !== 'Vencida' && order.paymentStateName !== 'Cancelado')"-->
                  <!--                        (click)="payNow(order.orderId,order)"-->
                  <!--                        ngbTooltip="Pagar Ahora">-->
                  <!--                  <i class="icon-bag"></i>-->
                  <!--                </button>-->
                  <!--                <button class="btn btn-primary mr-1 btn-sm"-->
                  <!--                        *ngIf=" order.paymentStateName == 'Pagada'"-->
                  <!--                        ngbTooltip="Cambiar Ubicación">-->
                  <!--                  <i class="icon-location-pin"></i>-->
                  <!--                </button>-->
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!-- Alert: Search not Found -->
          <div class="alert alert-warning" *ngIf="!userOrdersFiltered.length">
            No se han encontrado pedidos para la búsqueda seleccionada <span class="color-base">{{searchText}}</span>
          </div>
          <div class="row d-flex justify-content-between align-items-center">
            <div class="col-auto">
              <ngb-pagination
                [(page)]="pagination.page"
                [pageSize]="pagination.pageSize"
                [collectionSize]="pagination.total"
                [maxSize]="20"
                (pageChange)="changePage($event)">
              </ngb-pagination>
            </div>
            <div class="col-auto">
              <div *ngIf="userOrdersFiltered.length">
                Mostrando {{((bigCurrentPage * requestOrders.limit) - requestOrders.limit) + 1}} a
                {{(bigCurrentPage * requestOrders.limit)}} de {{bigTotalItems}} Pedidos
              </div>              
            </div>
          </div>
        </div>
      </div>
      <!-- Table Orders Mobile -->
      <div class="row d-flex d-lg-none mb-5">
        <div class="col-lg-12">
          <!-- Card Orders -->
          <div class="card mb-1" *ngFor="let order of userOrdersFiltered">
            <div class="card-body d-flex justify-content-between">
              <div class="small">
                <span class="w700">Pedido:</span> {{order.orderNumber}}<br>
                <span class="w700">Valor:</span> $ {{order.totalPrice | number : '1.0-0'}} COP<br>
                <span class="w700">Destino:</span> {{order.ciudadDestino}}<br>
                <span class="w700">Estado:</span> {{order.orderState}}<br>
                <span class="w700">{{order.paymentStateName}}</span>
              </div>
              <div class="d-flex flex-column justify-content-between">
                <div>
                  <div class="badge badge-success text-capitalize p-2" *ngIf="order.cancelState == 'activa'">
                    {{order.cancelState}}</div>
                  <div class="badge badge-danger text-capitalize p-2" *ngIf="order.cancelState == 'anulada'">
                    {{order.cancelState}}</div>
                </div>
                <div>
                  <button class="btn btn-primary mr-1 btn-sm" [routerLink]="['/order/', order.orderId]"
                          title="Ver pedido No. {{order.orderNumber}}">
                    <i class="icon-magnifier-add"></i> Ver Pedido
                  </button>
                  <!--                <button class="btn btn-primary mr-1 btn-sm"-->
                  <!--                        *ngIf="parametersService.company?.config?.orders?.duplicateOrders"-->
                  <!--                        (click)="duplicateOrder(order)"><i class="icon-docs"></i>-->
                  <!--                </button>-->
                  <!--                <button class="btn btn-primary mr-1 btn-sm"-->
                  <!--                        *ngIf="!authService.currentUserValue?.isSeller && parametersService.company?.config?.orders?.paySavedOrders && !authService.currentUserValue?.isB2B && order.showPayOrderAction && order.cancelState == 'activa' && (order.paymentStateName !== 'Pagada' && order.paymentStateName !== 'Vencida' && order.paymentStateName !== 'Cancelado')"-->
                  <!--                        (click)="payNow(order.orderId,order)"><i class="icon-basket-loaded"></i></button>-->
                  <!--                <button class="btn btn-primary mr-1 btn-sm"-->
                  <!--                        *ngIf=" order.paymentStateName == 'Pagada'"-->
                  <!--                        ui-sref="home.tracking({orderId : order.orderId})"><i class="icon-location-pin"></i></button>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>