<!-- Newsletter -->
<div class="container-fluid">
  <div class="newsletter pt-3 pt-lg-0">
    <div class="row w-100">
      <div class="col-12 col-lg-5 d-flex justify-content-center pl-5">
        <div class="d-flex align-items-center justify-content-center">
          <img src="/assets/images/icon-newsletter.png" class="mr-4">
          <div>
            <h4 class="text-primary text-uppercase m-0">Suscríbete</h4>
            <p class="m-0">Suscríbete ahora para recibir actualizaciones sobre promociones y cupones</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-7 d-flex justify-content-center">
        <form class="newsletter-form d-flex align-items-center" [formGroup]="subscribeForm" (submit)="sendSubscribe()" novalidate>
          <fieldset [disabled]="loadingSubscribeForm">
            <div class="row mt-3 mt-lg-0">
              <div class="col-12 col-lg mb-1 mb-lg-0 p-lg-0">
                <app-input class="newsletter-form-input small"
                  placeholder="Ingresa tu nombre"
                  [inputControl]="subscribeForm.get('name')"
                  [feedback]="{ required: 'Nombre es requerido' }"></app-input>
              </div>
              <div class="col-12 col-lg mb-1 mb-lg-0 p-lg-0">
                <app-input class="newsletter-form-input small"
                  placeholder="Ingresa tu correo electrónico"
                  [inputControl]="subscribeForm.get('email')"
                  [feedback]="{ required: 'Correo electrónico es requerido', pattern: 'Correo electrónico invalido' }"></app-input>
              </div>
              <div class="col-12 col-lg mb-1 mb-lg-0 p-lg-0">
                <button class="newsletter-form-button btn btn-primary btn-sm" type="submit" [disabled]="subscribeForm.invalid || loadingSubscribeForm">
                  <span *ngIf="loadingSubscribeForm">
                    <i class="fas fa-spinner fa-pulse mr-1"></i>
                  </span>
                  <span class="d-inline-block">Suscribirte</span>
                </button>
              </div>
            </div>
          </fieldset>
        </form>
        <!-- <p class="small mt-2 mb-0 text-center">
          *Al hacer clic en
          <span class="w700">Suscribirse</span>, estás aceptando nuestros
          <a class="w700" [routerLink]="['/', 'politics', 'terminos']">términos y condiciones.</a>
        </p> -->
      </div>
    </div>
  </div>
</div>

<!-- Footer -->
<div class="footer">
  <div class="container-fluid">
    <div class="row">
      <!-- Info Company -->
      <div class="col-12 col-lg-3 offset-lg-1 mb-3 mb-lg-0">
        <!-- Logo -->
        <div class="mb-4">
          <app-advertisements *ngIf="cmsData != undefined" [typeContent]="'espacios'" [positions]="1" [contentData]="cmsData"></app-advertisements>
        </div>
        <!-- Text -->
        <div class="small">
          <app-advertisements *ngIf="cmsData != undefined" [typeContent]="'espacios'" [positions]="2" [contentData]="cmsData"></app-advertisements>
        </div>
        <!-- Social Networks -->
        <ul class="social-networks list-inline d-flex mb-0">
          <li *ngIf="parametersService.company?.info?.social?.facebook?.show" class="social-networks-item list-inline-item">
            <a class="social-networks-link" [href]="parametersService.company?.info?.social?.facebook?.link" target="_blank">
              <i class="fab fa-facebook-f m-0"></i>
            </a>
          </li>
          <li *ngIf="parametersService.company?.info?.social?.instagram?.show" class="social-networks-item list-inline-item">
            <a class="social-networks-link" [href]="parametersService.company?.info?.social?.instagram?.link" target="_blank">
              <i class="fab fa-instagram m-0"></i>
            </a>
          </li>
          <li *ngIf="parametersService.company?.info?.social?.youtube?.show" class="social-networks-item list-inline-item">
            <a class="social-networks-link" [href]="parametersService.company?.info?.social?.youtube?.link" target="_blank">
              <i class="fab fa-youtube m-0"></i>
            </a>
          </li>
          <li *ngIf="parametersService.company?.info?.social?.twitter?.show" class="social-networks-item list-inline-item">
            <a class="social-networks-link" [href]="parametersService.company?.info?.social?.twitter?.link" target="_blank">
              <i class="fab fa-twitter -0"></i>
            </a>
          </li>
          <li *ngIf="parametersService.company?.info?.social?.pinterest?.show" class="social-networks-item list-inline-item">
            <a class="social-networks-link" [href]="parametersService.company?.info?.social?.pinterest?.link" target="_blank">
              <i class="fab fa-pinterest m-0"></i>
            </a>
          </li>
          <li *ngIf="parametersService.company?.info?.social?.linkedin?.show" class="social-networks-item list-inline-item">
            <a class="social-networks-link" [href]="parametersService.company?.info?.social?.linkedin?.link" target="_blank">
              <i class="fab fa-linkedin-in m-0"></i>
            </a>
          </li>
        </ul>
      </div>
      <!-- Contact Us -->
      <div class="col-12 col-lg-4">
        <h3 class="h6 text-uppercase text-white mb-4">Contáctenos</h3>
        <ul class="list-unstyled">
          <li class="d-flex align-items-center mb-4">
            <img src="/assets/images/icon-direccion.png">
            <div class="ml-3">
              <span class="d-block" *ngIf="parametersService.company?.info?.company?.address">
                {{parametersService.company?.info?.company?.address}}
              </span>
              <h6 class="text-uppercase small" *ngIf="parametersService.company?.info?.company?.location">
                {{parametersService.company?.info?.company?.location}}
              </h6>
            </div>
          </li>
          <li class="d-flex align-items-center mb-4">
            <img src="/assets/images/icon-automotriz.png">
            <div class="ml-3">
              <span class="d-block" *ngIf="parametersService.company?.info?.company?.email">
                {{parametersService.company?.info?.company?.email}}
              </span>
              <span class="d-block" *ngIf="parametersService.company?.info?.company?.pbx">
                {{parametersService.company?.info?.company?.pbx}}
              </span>
              <h6 class="text-uppercase small">Automotriz / Motos</h6>
            </div>
          </li>
          <li class="d-flex align-items-center mb-4">
            <img src="/assets/images/icon-bicicletas.png">
            <div class="ml-3">
              <span class="d-block" *ngIf="parametersService.company?.info?.company?.phone">
                {{parametersService.company?.info?.company?.phone}}
              </span>
              <span class="d-block" *ngIf="parametersService.company?.info?.company?.phone3">
                {{parametersService.company?.info?.company?.phone3}}
              </span>
              <h6 class="text-uppercase small">Bicicletas</h6>
            </div>
          </li>
        </ul>
      </div>
      <!-- Information -->
      <div class="col-12 col-lg-2 mb-2 mb-lg-0">
        <h3 class="h6 text-uppercase text-white mb-4">Información</h3>
        <ul class="list-unstyled small">
          <li class="mb-2">
            <a class="footer-link" [routerLink]="['/', 'politics', 'preguntas-frecuentes']">Preguntas Frecuentes</a>
          </li>
          <li class="mb-2">
            <a class="footer-link" [routerLink]="['/', 'politics', 'terminos-y-condiciones']">Términos de uso</a>
          </li>
          <li class="mb-2">
            <a class="footer-link" [routerLink]="['/', 'politics', 'politicas-de-tratamiento-de-datos']">Protección de Datos</a>
          </li>
          <li class="mb-2">
            <a class="footer-link" [routerLink]="['/','contact']">Contáctenos</a>
          </li>
          <li class="mb-2">
            <a class="footer-link" [routerLink]="['/', 'politics' ,'derecho-al-retracto']">Derecho al Retracto</a>
          </li>
          <li class="mb-2">
            <a class="footer-link" [routerLink]="['/', 'politics' ,'politicas-de-privacidad']">Clausula de Privacidad</a>
          </li>
        </ul>
      </div>
      <!-- Account -->
      <div class="col-12 col-lg-2">
        <h3 class="h6 text-uppercase text-white mb-4">Mi Cuenta</h3>
        <ul class="list-unstyled small">
          <li class="mb-2" *ngIf="!authService.isAuthenticated">
            <a class="footer-link" [routerLink]="['/','login']">Iniciar Sesión</a>
          </li>
          <li class="mb-2" *ngIf="!authService.isAuthenticated">
            <a class="footer-link" [routerLink]="['/','register']">Registrarse</a>
          </li>
          <li class="mb-2" *ngIf="!authService.isAuthenticated">
            <a class="footer-link" [routerLink]="['/','password-recovery']">Recuperar Contraseña</a>
          </li>
          <li class="mb-2" *ngIf="authService.isAuthenticated">
            <a class="footer-link" [routerLink]="['/','account']">Actualice sus Datos</a>
          </li>
          <li class="mb-2" *ngIf="authService.isAuthenticated">
            <a class="footer-link" [routerLink]="['/','favorites']">Mis Favoritos</a>
          </li>
          <li class="mb-2" *ngIf="authService.isAuthenticated">
            <a class="footer-link" [routerLink]="['/','orders']">Estado de Mis Compras</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Copyright -->
  <div class="copyright d-flex align-items-center">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-lg-5 offset-lg-1 text-center text-lg-left mb-2 mb-lg-0">
          <span class="small">
            Copyright {{parametersService.company?.app?.footer?.info?.date | date:'yyyy' }} ©
            {{parametersService.company?.info?.company?.name}} - Todos los derechos reservados | Powered by Siesa E-Commerce
          </span>
        </div>
        <div class="col-12 col-lg-5 d-flex justify-content-end">
          <app-advertisements *ngIf="cmsData != undefined" [typeContent]="'espacios'" [positions]="3" [contentData]="cmsData"></app-advertisements>
        </div>
        <div class="col-12 col-lg-1"></div>
      </div>
    </div>
  </div>
</div>

<!-- Cookies -->
<div class="container-fluid cookies small text-center" *ngIf="!cookiesAccepted">
  <div class="row d-flex">
    <div class="col-12">
      <span>
        Utilizamos cookies para asegurar que damos la mejor experiencia al usuario en nuestro sitio web. Si continúa
        utilizando este sitio asumiremos que está de acuerdo.
      </span>
      <div class="btn-group">
        <button class="btn btn-light btn-sm ml-1" (click)="enableCookies()">
          Estoy de Acuerdo
        </button>
        <button class="btn btn-light btn-sm ml-1" [routerLink]="['/', 'politics', 'cookies']">
          Políticas de Cookies
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Scroll Top -->
<button class="scroll-top-link btn" id="scroll-top" title="Ir a Arriba"
        [hidden]="!showUpButton"
        (scroll)="onScroll()"
        (click)="scrollToTop()">
  <i class="fa fa-angle-up"></i>
</button>
<div *ngIf="mainService.chatScript" [innerHtml]="mainService.chatScript"></div>

<!-- Compare Products Desktop -->
<a *ngIf="getNumberElementToCompare() >= 2"
   [routerLink]="['/compare-products', getStrElementsToCompare()]"
   role="button"
   class="compare-products btn d-lg-inline-block d-none"
   title="Comparar Productos">
  Comparar Productos ({{getNumberElementToCompare()}})
</a>

<!-- Compare Products Mobile -->
<a *ngIf="getNumberElementToCompare() >= 2"
   [routerLink]="['/compare-products', getStrElementsToCompare()]"
   role="button"
   class="compare-products btn d-lg-none d-inline-block"
   title="Comparar Productos">
  <!--  <i class="icon icon-refresh h4"></i> ({{getNumberElementToCompare()}})-->
  <i class="icon icon-refresh h4"></i>
</a>
