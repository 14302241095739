<!-- Breadcrumb -->
<nav class="breadcrumb bg-transparent mb-4 p-0 small">
  <ul class="breadcrumb-list list-inline d-flex mb-0">
    <li class="breadcrumb-list-item list-inline-item mr-0"><a class="breadcrumb-list-link" [routerLink]="['/']">Home</a></li>
    <li class="breadcrumb-list-item list-inline-item mr-0">{{slug}}</li>
  </ul>
</nav>

<div class="container mb-5">
  <app-advertisements *ngIf="cms$ | async as cmsData"
    [typeContent]="'espacios'"
    [positions]="1"
    [contentData]="cmsData">
  </app-advertisements>
</div>